<template>
  <v-card>
    <v-card-title> {{ $t("common.edit")  + ": " + institutionData.title }}</v-card-title>
    <v-card-text>
      <v-text-field
        v-model="institutionData.title"
        variant="outlined"
        :label="$t('institution.title')"
      />
      <v-switch
        color="primary"
        v-model="institutionData.allowsJoinRequests"
        label="Allow Join Requests"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn
        color="secondary"
        @click="$emit('close')"
      >
        {{ $t("buttons.cancel") }}
      </v-btn>
      <v-btn
        color="primary"
        @click="editInstitution"
      >
        {{ $t("buttons.confirm") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { PropType, ref} from "vue";
import yapi from "@/lib/yapi";
import {Institution, isYapiError, YapiError} from "@YenzaCT/sdk";
import {useGlobalStore} from "@/store";
import {useRoute} from "vue-router";
import {useI18n} from "vue-i18n";

const {t} = useI18n();
const store = useGlobalStore();
const route = useRoute();

const institutionId = route.params.id as string;
const props = defineProps({
  institution: {
    type: Object as PropType<Institution>,
    required: true
  }
});

const institutionData = ref<Institution>({...props.institution});

const emits = defineEmits<{
  (e: "close"): void;
}
>();

const editInstitution = async () => {
  try {
    store.networkBusy = true;
    await yapi.admin.institution.edit(institutionId, {
      title: institutionData.value.title,
      allowsJoinRequests: institutionData.value.allowsJoinRequests
    });
    emits("close");
    store.showSnackbar(t("components.EditInstitution.update_message"));
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
  }
};

</script>

<style scoped>

</style>
