<template>
  <v-container>

    <v-row v-if="!assessmentComplete">
      <v-col>
        <h2>{{ $t("assessments.assessment_not_done") }}</h2>
      </v-col>
    </v-row>

    <holland-code-skeleton-loader v-else-if="loading"/>

    <v-row v-else-if="score && assessmentComplete">
      <v-row>
        <v-col v-for="trait in ['primary', 'secondary']" :key="trait">
          <trait-score-card
            :title="hollandCodeReportContent.worker_types[trait].title"
            :score="hollandCodeReportContent.worker_types[trait].score * 100"
            :s-v-g-icon="traitProperties[hollandCodeReportContent.worker_types[trait].code!].icon"
            :colour="traitProperties[hollandCodeReportContent.worker_types[trait].code].colour"
            :data-pw="`${trait}TraitScoreCard`"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card color="#F5F5F8">
            <v-card-text>
              <wide-trait-score-card
                :title="getDefinition('C')"
                :result="hollandCodeReportContent.scores.C * 100"
                :s-v-g-icon="organiser(25)"
                colour="#ff3520"
                data-pw="organiserTraitScoreCard"
              />
              <v-divider/>
              <wide-trait-score-card
                :title="getDefinition('R')"
                :result="hollandCodeReportContent.scores.R * 100"
                :s-v-g-icon="builder(25)"
                colour="#fc631d"
                data-pw="builderTraitScoreCard"
              />
              <v-divider/>
              <wide-trait-score-card
                :title="getDefinition('A')"
                :result="hollandCodeReportContent.scores.A * 100"
                :s-v-g-icon="creator(25)"
                colour="#1eac30"
                data-pw="creatorTraitScoreCard"
              />
              <v-divider/>
              <wide-trait-score-card
                :title="getDefinition('E')"
                :result="hollandCodeReportContent.scores.E * 100"
                :s-v-g-icon="influencer(25)"
                colour="#514ee4"
                data-pw="influencerTraitScoreCard"
              />
              <v-divider/>
              <wide-trait-score-card
                :title="getDefinition('I')"
                :result="hollandCodeReportContent.scores.I * 100"
                :s-v-g-icon="thinker(25)"
                colour="#0487ff"
                data-pw="thinkerTraitScoreCard"
              />
              <v-divider/>
              <wide-trait-score-card
                :title="hollandCodeReportContent.definitions.filter((definition: Definitions) => definition.code === 'S')[0].title"
                :result="hollandCodeReportContent.scores.S * 100"
                :s-v-g-icon="helper(25)"
                colour="#ff5d5d"
                data-pw="helperTraitScoreCard"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <worker-type-overview :definitions="hollandCodeReportContent.definitions"/>
        </v-col>
      </v-row>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import {builder, creator, influencer, organiser, thinker, helper} from "@/assets/reportSvgs";
import WideTraitScoreCard from "@/pages/UserDetailPage/AsessmentsDetail/WideTraitScoreCard.vue";
import WorkerTypeOverview from "@/pages/UserDetailPage/AsessmentsDetail/HollandCodeReport/WorkerTypeOverview.vue";
import {PropType, computed, ref} from "vue";
import {HollandCodeResult, isYapiError, HollandCodeReportContent, YapiError, Definitions} from "@YenzaCT/sdk";
import TraitScoreCard from "@/pages/UserDetailPage/AsessmentsDetail/TraitScoreCard.vue";
import yapi from "@/lib/yapi";
import {AxiosResponse} from "axios";
import {useGlobalStore} from "@/store";
import HollandCodeSkeletonLoader
  from "@/pages/UserDetailPage/AsessmentsDetail/HollandCodeReport/HollandCodeSkeletonLoader.vue";
import {} from "@YenzaCT/sdk";

const store = useGlobalStore();

const props = defineProps({
  score: {
    type: Object as PropType<HollandCodeResult>,
    required: true
  }
});

const dataLoaded = ref(false);
const loading = ref(false);
const hollandCodeReportContent = ref();
const definitions = ref();

const assessmentComplete = computed(() => {
  if (props.score) {
    type ScoreTypeKey = keyof typeof props.score.types;
    const requiredKeys: ScoreTypeKey[] = ["R", "I", "A", "S", "E", "C"];

    // Check if all required keys exist in props.score.types
    const allKeysExist = requiredKeys.every((key) => key in props.score.types);
    if (allKeysExist) {
      return requiredKeys.every((type) => {
        const scoreType = type as ScoreTypeKey;
        const scoreValue = props.score.types[scoreType]?.score;
        return scoreValue !== undefined && scoreValue !== null;
      });
    }
  }
  return false;
});

function getDefinition(traitCode: string) {
  return hollandCodeReportContent.value.definitions.filter((definition: Definitions) => definition.code === traitCode)[0].title;
}

async function fetchHollandCodeReportContent() {
  loading.value = true;
  try {
    const res: AxiosResponse<HollandCodeReportContent> = await yapi.assessmentReport.getWorkerTypeReportContent();
    hollandCodeReportContent.value = res.data;
    definitions.value = res.data.definitions;
    dataLoaded.value = true;
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    loading.value = false;
  }
}

fetchHollandCodeReportContent();

interface TraitProperties {
  [index: string]: TraitProperty;

  R: TraitProperty;
  I: TraitProperty;
  A: TraitProperty;
  S: TraitProperty;
  E: TraitProperty;
  C: TraitProperty;
}

interface TraitProperty {
  slug: string;
  icon: string;
  colour: string;
}

const traitProperties: TraitProperties = {
  R: {
    slug: "builder",
    icon: builder(50),
    colour: "#fc631d"
  },
  I: {
    slug: "thinker",
    icon: thinker(50),
    colour: "#0487ff"
  },
  A: {
    slug: "creator",
    icon: creator(50),
    colour: "#1eac30"
  },
  S: {
    slug: "helper",
    icon: helper(50),
    colour: "#ff5d5d"
  },
  E: {
    slug: "influencer",
    icon: influencer(50),
    colour: "#514ee4"
  },
  C: {
    slug: "organiser",
    icon: organiser(50),
    colour: "#ff3520"
  },
};
</script>

<style scoped>

</style>
