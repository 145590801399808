<template>
  <unauthenticated-header />
  <div class="bg-primary authentication-wrapper">
    <v-container class="fill-height">
      <v-row>
        <v-col cols="12" class="text-center">
          <h1>Welcome to the {{ appName }} Dash</h1>
        </v-col>
        <v-col cols="12">
          <v-card width="384px" class="ma-auto" elevation="6">
            <v-card-title>{{ $t("components.LoginPage.title") }}</v-card-title>
            <v-card-subtitle>
              {{ $t("components.LoginPage.welcome_back_message") }}
            </v-card-subtitle>
            <v-divider class="mx-4 mt-2" />
            <v-card-text>
              <login-form/>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script setup lang="ts">
import {onMounted} from "vue";
import {useGlobalStore} from "@/store";
import {useRouter} from "vue-router";
import LoginForm from "@/pages/LoginPage/LoginForm.vue";
import UnauthenticatedHeader from "@/layout/UnauthenticatedHeader.vue";

const store = useGlobalStore();
const router = useRouter();

const appName = store.appConfiguration?.appName;

onMounted(() => {
  if (store.user) router.push("/");
});
</script>

<style scoped>
.authentication-wrapper {
  height: 100vh;
}
</style>
