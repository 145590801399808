<template>
  <v-card v-if="Object.keys(availableCurricula).length > 0 " class="ma-4" :fluid="true">
    <v-card-title>
      {{
        $t("components.EditInstitutionCurriculum.card_title")
      }}
    </v-card-title>
    <v-card-text>
      <v-row>
        <!-- Allows users to select which curriculum are enabled -->
        <v-col cols="12" class="bg-grey-lighten-4">
          <h4>
            {{
              $t("components.EditInstitutionCurriculum.title_select_available_curriculums")
            }}
          </h4>
          <p class="pb-4">
            {{
              $t("components.EditInstitutionCurriculum.instruction_select_curriculums_enabled")
            }}
          </p>
          <v-checkbox
            v-for="(curriculum, curriculumIndex) in availableCurricula"
            :hide-details="true"
            density="compact"
            :key="`${curriculumIndex}_checkbox`"
            v-model="availableCurricula[curriculumIndex].enabled"
            :label="$t(`curriculums.${curriculum.slug}.title`)"
            dense
            class="ma-0 pa-0"
            @click="selectCurriculum(curriculumIndex)"
          />
        </v-col>
      </v-row>

      <v-row class="bg-grey-lighten-4 mt-6">
        <v-col cols="3">
          <!-- Allows users to select a curriculum to modify -->
          <h4>
            {{
              $t("components.EditInstitutionCurriculum.title_select_curriculum")
            }}
          </h4>
          <div v-if="Object.keys(availableCurricula).length >0">
            <span class="text-caption">
              {{
                $t("components.EditInstitutionCurriculum.instruction_select_curriculum_below")
              }}
            </span>
            <v-list class="mt-2 rounded">
              <v-list-item
                v-model="activeCurriculum"
                @click="setActiveCurriculum(curriculumIndex)"
                :value="curriculum"
                :key="curriculum.slug"
                v-for="(curriculum, curriculumIndex ) in availableCurricula"
                :disabled="!curriculum.enabled"
              >
                {{
                  $t(`curriculums.${curriculum.slug}.title`)
                }}
              </v-list-item>
            </v-list>
          </div>
          <div v-else>
            <span class="text-caption">
              {{
                $t("components.EditInstitutionCurriculum.instruction_select_curriculum_above")
              }}
            </span>
          </div>
        </v-col>
        <v-col cols="3">
          <h4>
            {{
              $t("components.EditInstitutionCurriculum.title_subject_groups")
            }}
          </h4>
          <span class="text-caption">
            {{
              $t("components.EditInstitutionCurriculum.instruction_click_subject_group")
            }}
          </span>
          <v-list class="mt-2 rounded" v-if="activeCurriculum">
            <v-list-item
              @click="activeSubjectGroup = subjectGroupIndex"
              :key="subjectGroupIndex"
              :active="activeSubjectGroup === subjectGroupIndex"
              v-for="(subjectGroup, subjectGroupIndex) in availableCurricula[activeCurriculum].subjectGroups">
              {{ $t(`curriculums.${activeCurriculum}.subject_groups.${subjectGroup.slug}`) }}
              <v-chip
                :color="getChipColor(activeCurriculum, subjectGroupIndex)"
                size="x-small">
                {{
                  Object.values(subjectGroup.subjects)
                    .filter(subject => subject.enabled === true).length
                }}
                selected
              </v-chip>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="6">
          <h4 v-if="activeCurriculum && activeSubjectGroup">
            {{ $t(`curriculums.${activeCurriculum}.subject_groups.${availableCurricula[activeCurriculum].subjectGroups[activeSubjectGroup].slug}`) }}
            Subjects</h4>
          <span v-if="activeCurriculum && activeSubjectGroup" class="text-caption">
            {{
              $t("components.EditInstitutionCurriculum.instruction_select_subject")
            }}
            {{
              $t(`curriculums.${activeCurriculum}.subject_groups.${availableCurricula[activeCurriculum].subjectGroups[activeSubjectGroup].slug}`)
            }}.</span>
          <div v-if="activeSubjectGroup">
            <v-btn-group class="my-4" density="compact">
              <v-btn @click="resetSubjectSelection(activeCurriculum, activeSubjectGroup)">
                {{ $t("components.EditInstitutionCurriculum.buttons.select_none") }}
              </v-btn>
              <v-btn @click="selectAllSubjects(activeCurriculum, activeSubjectGroup)">
                {{ $t("components.EditInstitutionCurriculum.buttons.select_all") }}
              </v-btn>
              <v-menu>
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props">
                    {{ $t("components.EditInstitutionCurriculum.buttons.copy") }}
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    :key="`copy_${subjectGroupIndex}`"
                    v-for="(subjectGroup, subjectGroupIndex) in availableCurricula[activeCurriculum].subjectGroups"
                    @click="copySubjectGroupSelection(activeCurriculum, subjectGroupIndex, activeSubjectGroup)"
                  >
                    {{
                      $t(`curriculums.${activeCurriculum}.subject_groups.${subjectGroup.slug}`)
                    }}
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-btn-group>
            <v-checkbox
              density="compact"
              :hide-details="true"
              v-for="(subject, subjectIndex) in availableCurricula[activeCurriculum].subjectGroups[activeSubjectGroup].subjects"
              v-model="availableCurricula[activeCurriculum].subjectGroups[activeSubjectGroup].subjects[subjectIndex].enabled"
              :key="subject.slug"
              dense
              :label="$t(`curriculums.${activeCurriculum}.subjects.${subject.slug}`)"
              class="pa-0 ma-0"
            />
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn
        data-pw="cancelButton"
        color="secondary"
        @click="$emit('close')"
      >
        {{ $t("buttons.cancel") }}
      </v-btn>
      <v-btn
        color="primary"
        data-pw="editInstitutionButton"
        @click="editInstitution"
      >
        {{ $t("buttons.confirm") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import {onMounted, PropType, ref} from "vue";
import {Curriculum, isYapiError, YapiError, Subject, SubjectGroup, InstitutionCurriculum} from "@YenzaCT/sdk";
import yapi from "@/lib/yapi";
import {useGlobalStore} from "@/store";
import {useRoute} from "vue-router";
import {useI18n} from "vue-i18n";

const { t } = useI18n();
const store = useGlobalStore();
const route = useRoute();

const props = defineProps({
  title: {
    type: String as PropType<string>,
    required: true
  },
  curricula: {
    type: Array as PropType<InstitutionCurriculum[]>,
    required: true
  }
});

interface SelectedCurriculum extends Curriculum {
  enabled: boolean
  subjectGroups: Record<string, SelectedSubjectGroup>;
}

interface SelectedSubjectGroup extends SubjectGroup {
  enabled: boolean
  subjects: Record<string, SelectedSubject>;
}
interface SelectedSubject extends Subject {
  enabled: boolean
}
interface SelectedCurricula {
  [key: string]: SelectedCurriculum;
}

const availableCurricula = ref<Record<string, SelectedCurriculum>>({});
const activeSubjectGroup = ref<string>("");
const activeCurriculum = ref<string>("");
const institutionId = route.params.id as string;

onMounted(async () => {
  await fetchCurricula();
});

const emits = defineEmits<{
  (e: "close"): void;
}
>();

/**
 * Sets the curriculum the user is currently editing
 * @param curriculum The curriculum to set as active.
 */
function setActiveCurriculum(curriculum: string) {
  activeCurriculum.value = curriculum;
  activeSubjectGroup.value = "";
}

/**
 * Gets the color for the chip in the subject group list based on mandatory or not
 * @param curriculumId The curriculum id to check
 * @param subjectGroup The subject group to check
 */
function getChipColor(curriculumId: string, subjectGroup: string) {
  const {subjectGroups} = availableCurricula.value[curriculumId];
  const {
    mandatory,
    subjects
  } = subjectGroups[subjectGroup];
  const selectedCount = Object.values(subjects)
    .filter((subject) => subject.enabled).length;

  if (selectedCount > 0) return "green";
  if (mandatory) return "red";
  return "grey";
}

/**
 * Resets the subject selection for a group
 * @param curriculumId The curriculum id to reset
 * @param group The group to reset
 */
function resetSubjectSelection(curriculumId: string, group: string) {
  const subjects = availableCurricula.value[curriculumId].subjectGroups[group].subjects;

  for (const subject in subjects) {
    availableCurricula.value[curriculumId].subjectGroups[group].subjects[subject].enabled = false;
  }
}

/**
 * Selects all the available subjects in a group.
 * @param curriculumId The curriculum id to select from
 * @param group The group to select from
 */
function selectAllSubjects(curriculumId: string, group: string) {
  const subjects = availableCurricula.value[curriculumId].subjectGroups[group].subjects;

  for (const subject in subjects) {
    availableCurricula.value[curriculumId].subjectGroups[group].subjects[subject].enabled = true;
  }
}

/**
 * Copies the selected subjects from one group to another, when they exist in the destination
 * @param curriculumId The curriculum id to copy from
 * @param sourceGroup The group to copy from
 * @param destinationGroup The group to copy to
 */
function copySubjectGroupSelection(curriculumId: string, sourceGroup: string, destinationGroup: string) {
  const sourceSubjects = availableCurricula.value[curriculumId].subjectGroups[sourceGroup].subjects;
  const destinationSubjects = availableCurricula.value[curriculumId].subjectGroups[destinationGroup].subjects;

  for (const subject in sourceSubjects) {
    if (destinationSubjects[subject]) {
      availableCurricula.value[curriculumId].subjectGroups[destinationGroup].subjects[subject].enabled = false;

      availableCurricula.value[curriculumId].subjectGroups[destinationGroup].subjects[subject].enabled =
        availableCurricula.value[curriculumId].subjectGroups[sourceGroup].subjects[subject].enabled;
    }
  }
}

/**
 * Adds a selected curriculum to the selectedCurriculaData object.
 * @param curriculumId The curriculum id to add.
 */
function selectCurriculum(curriculumId: string) {
  availableCurricula.value[curriculumId].enabled = !availableCurricula.value[curriculumId].enabled;
}

/**
 * Fetches the curricula from the API.
 */
async function fetchCurricula() {
  try {
    store.networkBusy = true;
    const data = (await yapi.admin.curriculum.list()).data;
    setupCurricula(data as unknown as SelectedCurricula);
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
  }
}

/**
 * Transforms the curricula data from the API to a format that can be used in the component
 */
function setupCurricula(curricula: SelectedCurricula) {
  for (const curriculumKey in curricula) {
    const curriculum = curricula[curriculumKey];
    const selectedCurriculum = props.curricula.find((c) => c.slug === curriculumKey);

    curriculum.enabled = selectedCurriculum?.enabled || false;

    for (const subjectGroupKey in curriculum.subjectGroups) {
      const subjectGroup = curriculum.subjectGroups[subjectGroupKey];
      const selectedSubjectGroup = selectedCurriculum?.subjectGroups.find((c) => c.slug === subjectGroupKey);

      subjectGroup.enabled = subjectGroup.mandatory || false;

      for (const subjectKey in subjectGroup.subjects) {
        const subject = subjectGroup.subjects[subjectKey];
        const selectSubject = selectedSubjectGroup?.subjects.find((s) => s.slug === subjectKey);

        subject.enabled = selectSubject?.enabled || false;
      }
    }
    availableCurricula.value[curriculumKey] = curriculum;
  }
}

function removeDisabledSubjects(curriculaData: Record<string, SelectedCurriculum>) {
  for (const curriculumKey in curriculaData) {
    const curriculum = curriculaData[curriculumKey];
    for (const subjectGroupKey in curriculum.subjectGroups) {
      const subjectGroup = curriculum.subjectGroups[subjectGroupKey];
      subjectGroup.subjects = Object.fromEntries(
        Object.entries(subjectGroup.subjects)
          .filter(([, subject]) => subject.enabled)
      );
    }
  }
  return curriculaData;
}

/**
 * Submits the institution curricula to the API.
 */
const editInstitution = async () => {
  // First the data needs to be formatted to match the API's expected format.
  const curricula = removeDisabledSubjects(availableCurricula.value);

  try {
    store.networkBusy = true;
    await yapi.admin.institution.edit(institutionId, {
      title: undefined,
      curricula: curricula as unknown as InstitutionCurriculum[] // todo API takes non-standard format
    });
    emits("close");
    store.showSnackbar(t("components.EditInstitutionCurriculum.update_message"));
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
  }
};

</script>
