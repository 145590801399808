<script setup lang="ts">

import ColourSelector from "@/pages/AppConfigurationPage/ThemeEditor/ColourSelector.vue";
import {onMounted, ref} from "vue";
import {Json} from "@/lib/appConfiguration";
import {useGlobalStore} from "@/store";
import {convertRGBAToObject, convertRGBAObjectToString} from "@/lib/colors";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import {isYapiError, YapiError} from "@YenzaCT/sdk";
import ConfigurationSectionRow from "@/pages/AppConfigurationPage/ConfigurationSectionRow.vue";

const store = useGlobalStore();

const confirmVisible = ref(false);

const variables = ref({"cta-text-color": "#000", "text-base-color": "#000", ...store.appConfiguration.cssVariables});
const ctaBackground = ref({r: 0, g: 0, b: 0, a: 1});

onMounted(() => {
  if (variables.value["cta-background"])
    ctaBackground.value = convertRGBAToObject(variables.value["cta-background"]);
});

async function save(): Promise<void> {
  confirmVisible.value = false;

  const variableUpdate = {...variables.value};
  variableUpdate["cta-background"] = convertRGBAObjectToString(ctaBackground.value);

  try {
    await store.saveAppConfiguration("cssVariables", variableUpdate as unknown as Json);
    store.showSnackbar("CSS Variables saved");
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    confirmVisible.value = false;
  }
}
</script>

<template>
  <v-card flat color="grey-lighten-4">
    <v-card-title>
      Vuetify CSS Variables
    </v-card-title>
    <v-divider/>
    <v-card-text>
      <h3>Colors</h3>
      <colour-selector
        label="CTA Background Colour (RGBa)"
        v-model="ctaBackground"
        mode="rgba"
      />
      <colour-selector
        label="CTA Text Color"
        v-model="variables['cta-text-color']"
        mode="hex"
      />

      <v-divider class="my-2"/>
      <h3>Border Radius</h3>

      <configuration-section-row :dense="true" :show-divider="false">
        <template #name>
          Root Border Radius
        </template>
        <v-text-field
          v-model="variables['border-radius-root']"
          width="100px"
          density="compact"
          variant="solo"
          class="ml-auto"
        />
      </configuration-section-row>
      <configuration-section-row :dense="true" :show-divider="false">
        <template #name>
          Button Border Radius
        </template>
        <v-text-field
          v-model="variables['btn-border-radius']"
          width="100px"
          density="compact"
          variant="solo"
          class="ml-auto"
        />
      </configuration-section-row>
      <configuration-section-row :dense="true" :show-divider="false">
        <template #name>
          Text Field Border Radius
        </template>
        <v-text-field
          v-model="variables['text-field-border-radius']"
          width="100px"
          density="compact"
          variant="solo"
          class="ml-auto"
        />
      </configuration-section-row>
      <configuration-section-row :dense="true" :show-divider="false">
        <template #name>
          Chip Label Border Radius
        </template>
        <v-text-field
          v-model="variables['chip-label-border-radius']"
          width="100px"
          density="compact"
          variant="solo"
          class="ml-auto"
        />
      </configuration-section-row>
      <configuration-section-row :dense="true" :show-divider="false">
        <template #name>
          Expansion Panel Border Radius
        </template>
        <v-text-field
          v-model="variables['expansion-panel-border-radius']"
          width="100px"
          density="compact"
          variant="solo"
          class="ml-auto"
        />
      </configuration-section-row>

      <v-divider class="my-2"/>
      <h3>Buttons</h3>

      <configuration-section-row :dense="true" :show-divider="false">
        <template #name>
          Button Letter Spacing
        </template>
        <v-text-field
          v-model="variables['btn-letter-spacing']"
          width="100px"
          density="compact"
          variant="solo"
          class="ml-auto"
        />
      </configuration-section-row>

      <configuration-section-row :dense="true" :show-divider="false">
        <template #name>
          Button Font Weight
        </template>
        <v-text-field
          v-model="variables['btn-font-weight']"
          width="100px"
          density="compact"
          variant="solo"
          class="ml-auto"
        />
      </configuration-section-row>

      <configuration-section-row :dense="true" :show-divider="false">
        <template #name>
          Button Text Transform
        </template>
        <v-text-field
          v-model="variables['btn-text-transform']"
          width="100px"
          density="compact"
          variant="solo"
          class="ml-auto"
        />
      </configuration-section-row>

      <configuration-section-row :dense="true" :show-divider="false">
        <template #name>
          Button Icon Font Size
        </template>
        <v-text-field
          v-model="variables['btn-icon-font-size']"
          width="100px"
          density="compact"
          variant="solo"
          class="ml-auto"
        />
      </configuration-section-row>

      <configuration-section-row :dense="true" :show-divider="false">
        <template #name>
          Button Border Width
        </template>
        <v-text-field
          v-model="variables['btn-outline-border-width']"
          width="100px"
          density="compact"
          variant="solo"
          class="ml-auto"
        />
      </configuration-section-row>

      <v-divider class="my-2"/>
      <h3>Typography</h3>

      <configuration-section-row :dense="true" :show-divider="false">
        <template #name>
          Line Height
        </template>
        <v-text-field
          v-model="variables['text-line-height']"
          width="100px"
          density="compact"
          variant="solo"
          class="ml-auto"
        />
      </configuration-section-row>

      <configuration-section-row :dense="true" :show-divider="false">
        <template #name>
          Base Color
        </template>
        <colour-selector
          v-model="variables['text-base-color']"
          mode="hex"
        />
      </configuration-section-row>

    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn
        color="primary"
        @click="confirmVisible = true"
      >
        {{ $t("buttons.apply") }}
      </v-btn>
    </v-card-actions>
  </v-card>
  <confirm-dialog
    v-model="confirmVisible"
    @confirm="save"
    :title="$t('components.ThemeEditor.confirm_title')"
    :text="$t('components.ThemeEditor.confirm_text')"
  />
</template>

<style scoped>

</style>
