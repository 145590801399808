<template>
  <v-card>
    <v-card-text>
      <v-list>
        <v-list-item>
          <v-card-text data-pw="confirmDeleteMessage">
            {{ message }}
          </v-card-text>
          <v-text-field
            v-model="verifyString"
            :label="$t('components.DeleteVoucher.message_instruction') + confirmString"
            data-pw="confirmDeleteVoucher"
          >
          </v-text-field>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn
        data-pw="cancelButton"
        color="secondary"
        @click="$emit('close')"
      >
        {{ $t("buttons.cancel") }}
      </v-btn>
      <v-btn
        color="primary"
        data-pw="confirmDeleteButton"
        @click="deleteVoucher"
        v-bind:disabled="verifyString !== confirmString"
      >
        {{ $t("buttons.confirm") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import {Ref, ref} from "vue";
import yapi from "@/lib/yapi";
import router from "@/router";
import {isYapiError, YapiError} from "@YenzaCT/sdk";
import {useGlobalStore} from "@/store";
import {useRoute} from "vue-router";

defineProps({
  confirmString: {
    type: String,
    required: true
  },
  message: {
    type: String,
    required: true
  }
});

defineEmits<{
  (e: "confirm"): void;
  (e: "close"): void;
}>();

const store = useGlobalStore();

const route = useRoute();

const verifyString = ref("");

const voucherId: Ref<string> = ref("");

voucherId.value = route.params.id as string;

const deleteVoucher = async () => {
  try {
    await yapi.admin.accessCode.remove(voucherId.value);
    await router.push({name: "vouchers"});
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  }
};
</script>

<style scoped>

</style>
