<template>
  <authenticated-layout>
    <template #headerIcon>
      far fa-users
    </template>
    <template #header>
      {{ user?.profile.firstName }} {{ user?.profile.lastName }}
      <span v-if="!user?.profile.firstName && !user?.profile.lastName">
        {{ user?._id }}
      </span>
    </template>
    <template #breadcrumbs>
      <v-breadcrumbs
        density="compact"
        class="pl-0 text-caption"
      >
        <v-breadcrumbs-item :to="{ name: 'users' }" class="font-weight-bold" color="secondary">
          {{ $t("components.UserDetailPage.back_breadcrumb") }}
        </v-breadcrumbs-item>
        <v-breadcrumbs-divider/>
        <v-breadcrumbs-item color="primary">
          {{ user?.profile.firstName }} {{ user?.profile.lastName }}
          <span v-if="!user?.profile.firstName && !user?.profile.lastName">
            {{ user?._id }}
          </span>
        </v-breadcrumbs-item>
      </v-breadcrumbs>
    </template>
    <template #context-menu>
      <v-menu
        bottom
        transition="scale-transition"
      >
        <template v-slot:activator="{ props }">
          <v-btn
            color="primary"
            dark
            v-bind="props"
            prepend-icon="fa fa-bars"
          >
            {{ $t("buttons.menu") }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            :disabled="!isAdminOrRoot && !canEditAnyUsers"
            @click="showResetPasswordDialog = true"
            data-pw="resetPasswordDialogButton"
          >
            {{ $t("components.UserDetailPage.menu.reset_password") }}
          </v-list-item>
          <v-list-item
            :disabled="!isAdminOrRoot && !canEditAnyUsers"
            @click="showChangeEmailDialog = true"
            data-pw="changeEmailDialogButton"
          >
            {{ $t("components.UserDetailPage.menu.change_email") }}
          </v-list-item>
          <v-list-item
            :disabled="!isAdminOrRoot && !canEditAnyUsers"
            @click="showChangePhoneDialog = true"
            data-pw="changePhoneDialogButton"
          >
            {{ $t("components.UserDetailPage.menu.change_phone_number") }}
          </v-list-item>
          <v-list-item
            v-if="isAdminOrRoot"
            @click="showChangeAccountTypeDialog = true"
          >
            {{ $t("components.UserDetailPage.menu.change_account_type") }}
          </v-list-item>
          <v-list-item
            v-if="isAdminOrRoot"
            @click="showChangeRoleDialog = true"
          >
            {{ $t("components.UserDetailPage.menu.change_role") }}
          </v-list-item>
          <v-divider/>
          <v-list-item @click="showDownloadSubjectChoiceReportDialog = true">
            {{ $t("components.UserDetailPage.menu.download_subject_report") }}
          </v-list-item>
          <v-list-item @click="showDownloadCVDialog = true">
            {{ $t("components.UserDetailPage.menu.download_cv") }}<br/>
          </v-list-item>
          <v-list-item data-pw="downloadAssessmentDialogButton" @click="showDownloadAssessmentReportDialog = true" >
            {{ $t("components.UserDetailPage.menu.download_assessment_report") }} <br/>
          </v-list-item>
          <v-divider />
          <v-list-item
            v-if="isAdminOrRoot"
            @click="showResetAssessmentsDialog = true"
            data-pw="resetAssessmentsDialogButton"
          >
            {{ $t("components.UserDetailPage.menu.reset_assessments") }}
          </v-list-item>
          <v-divider v-if="isAdminOrRoot" />
          <v-list-item
            v-if="isAdminOrRoot"
            @click="showDisableEnableUser = true"
            data-pw="disableEnableUserDialogButton"
          >
            <p style="color:red;">
              {{
                user?.auth.accountDisabled
                  ? $t("components.UserDetailPage.menu.enable")
                  : $t("components.UserDetailPage.menu.disable")
              }}
            </p>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>

    <v-container
      v-if="user"
      fluid class="py-4"
    >
      <v-alert
        v-if="user?.auth.accountDisabled"
        type="warning"
        density="compact"
        class="my-4"
      >
        {{ $t("components.UserDetailPage.account_disabled") }}
        <a href="#" @click="showDisableEnableUser = true">enable</a>
      </v-alert>
      <v-row>
        <v-col cols="3">
          <v-list color="transparent">
            <v-list-item
              :active="section === 'profile'"
              active-class="bg-primary"
              @click="section = 'profile'"
              prepend-icon="far fa-user"
              :title="$t('components.UserDetailPage.user_detail_section_heading.profile')"
            />
            <v-list-item
              :active="section === 'assessments'"
              active-class="bg-primary"
              @click="section = 'assessments'"
              prepend-icon="far fa-file-chart-pie"
              :title="$t('components.UserDetailPage.user_detail_section_heading.assessment_reports')"
              data-pw="assessmentReportsMenuItem"
            />
            <v-list-item
              :active="section === 'choices'"
              active-class="bg-primary"
              @click="section = 'choices'"
              prepend-icon="far fa-ballot-check"
              :title="$t('components.UserDetailPage.user_detail_section_heading.choices')"
              data-pw="choicesMenuItem"
            />
            <v-list-item
              v-if="store.user?.app.role === Role.Admin"
              :active="section === 'permissions'"
              active-class="bg-primary"
              @click="section = 'permissions'"
              prepend-icon="fa fa-user-secret"
              :title="$t('components.UserDetailPage.user_detail_section_heading.permissions')"
            />
            <v-list-item
              :active="section === 'authentication'"
              active-class="bg-primary"
              @click="section = 'auth'"
              prepend-icon="far fa-lock"
              :title="$t('components.UserDetailPage.user_detail_section_heading.authentication')"
            />
          </v-list>
        </v-col>
        <v-col cols="9">
          <v-row v-if="section === 'profile'">
            <v-col cols="12">
              <profile-card
                :account-type="$t(`accountTypes.${user.app.accountType}`)"
                :role="$t(`roles.${user.app.role}`)"
                :user="user"
              />
            </v-col>
            <v-col cols="12">
              <progress-card
                :assessments="user.assessments"
                :account-type="user.app.accountType"
                :country="user.country"
                :profile="user.profile"
                :research="user.app.researchAssessments ?? []"/>
            </v-col>
          </v-row>
          <v-row v-if="section === 'assessments'">
            <v-col cols="12">
              <h2>
                {{
                  $t("components.UserDetailPage.user_detail_section_heading.assessment_reports")
                }}
              </h2>
            </v-col>
            <v-col cols="12">
              <assessments-detail
                :user="user"
                :account-type="user.app.accountType"
                :research="user.app.researchAssessments"
              />
            </v-col>
          </v-row>
          <v-row v-if="section === 'choices'">
            <v-col cols="12">
              <h2 class="mb-6">
                {{
                  $t("components.UserDetailPage.user_detail_section_heading.choices")
                }}
              </h2>
              <choices-detail :user="user"/>
            </v-col>
          </v-row>
          <v-row v-if="section ==='permissions'">
            <v-col cols="12">
              <h2>
                {{
                  $t("components.UserDetailPage.user_detail_manager_permissions.title")
                }}
              </h2>
            </v-col>

            <v-col v-if="user.app.role === Role.Manager">

              <v-card>
                <v-tabs
                  v-model="tab"
                  bg-color="primary"
                >
                  <v-tab value="view">
                    {{
                      $t("buttons.view")
                    }}
                  </v-tab>
                  <v-tab value="manage">
                    {{
                      $t("buttons.manage")
                    }}
                  </v-tab>
                </v-tabs>

                <v-card-text>
                  <v-window v-model="tab">
                    <v-window-item value="view">
                      <permissions-list :permissions="user.app.permissions"/>

                    </v-window-item>

                    <v-window-item value="manage">
                      <v-row>
                        <v-col cols="12">
                          <p>{{ $t("components.UserDetailPage.manager_instructions") }}</p>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">

                          <v-card>
                            <v-card-text>
                              <tenant-search-dropdown v-model="selectedTenant" />
                              <institution-search-dropdown v-model="selectedInstitution" :tenant="selectedTenant" />
                              <cohort-search-dropdown
                                v-model="selectedCohort"
                                :institution="selectedInstitution"
                                :tenant="selectedTenant"
                              />
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                      <v-row v-if="selectedTenant">
                        <v-col cols="12">
                          <v-card>
                            <v-card-title>{{ getSelectedEntityTitle }}</v-card-title>
                            <v-card-text>
                              <permission-checkboxes
                                :title="getSelectedEntityTitle"
                                v-model="checkboxesState"
                              />
                            </v-card-text>
                            <v-card-actions>
                              <v-btn color="green" @click="confirmPermission">Save</v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-window-item>
                  </v-window>
                </v-card-text>

              </v-card>

            </v-col>
            <v-col v-else>
              <v-alert type="info" class="mb-3" color="primary">
                {{
                  $t("components.UserDetailPage.user_detail_manager_permissions.warning")
                }}
              </v-alert>
              <v-btn variant="text" @click="showChangeRoleDialog = true">
                {{
                  $t("components.UserDetailPage.user_detail_manager_permissions.update_role_button")
                }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="section ==='auth'">
            <v-col cols="12">
              <h2>
                {{
                  $t("components.UserDetailPage.user_detail_section_heading.authentication")
                }}
              </h2>
            </v-col>
            <v-col cols="12">
              <basic-auth-card :user="user"/>
            </v-col>
          </v-row>

          <v-dialog v-model="showChangeEmailDialog" persistent max-width="500">
            <change-email-address-dialog
              @close="onCloseChangeEmailDialog"
              :user="user"
            />
          </v-dialog>
          <v-dialog v-model="showChangePhoneDialog" persistent max-width="500">
            <change-phone-dialog
              @close="onCloseChangePhoneDialog"
              :user="user"
            />
          </v-dialog>
          <v-dialog v-model="showChangeAccountTypeDialog" persistent max-width="500">
            <change-account-type-dialog
              @close="onCloseChangeAccountTypeDialog"
              :user="user"
            />
          </v-dialog>
          <v-dialog v-model="showDisableEnableUser" persistent max-width="500">
            <enable-disable-dialog
              @close="onDisableEnableDialog"
              :user="user"
            />
          </v-dialog>
          <v-dialog v-model="showResetAssessmentsDialog" persistent max-width="1000">
            <reset-assessments
              @close="onCloseResetAssessmentDialog"
              :title="$t('components.UserDetailPage.menu.reset_assessments')"
              :user="user"
            />
          </v-dialog>
          <v-dialog v-model="showResetPasswordDialog" persistent max-width="500">
            <reset-password-dialog
              :full-name="fullName"
              :user="user"
              @close="showResetPasswordDialog = false"
            />
          </v-dialog>
          <v-dialog v-model="showChangeRoleDialog" persistent max-width="500">
            <update-user-role
              :user="user"
              @close="showChangeRoleDialog = false"
            />
          </v-dialog>
          <v-dialog v-model="showDownloadSubjectChoiceReportDialog" persistent max-width="500">
            <download-subject-choice-dialog
              :user="user"
              @close="showDownloadSubjectChoiceReportDialog = false"
            />
          </v-dialog>
          <v-dialog v-model="showDownloadAssessmentReportDialog" persistent max-width="500">
            <download-assessment-dialog
              :user="user"
              @close="showDownloadAssessmentReportDialog = false"
            />
          </v-dialog>
          <v-dialog v-model="showDownloadCVDialog" persistent max-width="500">
            <download-cv-dialog
              :user="user"
              @close="showDownloadCVDialog = false"
            />
          </v-dialog>
        </v-col>

      </v-row>

    </v-container>

  </authenticated-layout>
</template>

<script setup lang="ts">
import {computed, onMounted, ref, Ref, watch} from "vue";
import {useRoute} from "vue-router";
import {
  Action,
  Cohort,
  Entity,
  Institution,
  isYapiError,
  Permission,
  Role,
  Tenant,
  DashUser,
  YapiError
} from "@YenzaCT/sdk";
import AuthenticatedLayout from "@/layout/AuthenticatedLayout.vue";
import ProfileCard from "@/pages/UserDetailPage/ProfileCard.vue";
import ProgressCard from "@/pages/UserDetailPage/ProgressCard.vue";
import ChoicesDetail from "@/pages/UserDetailPage/ChoicesDetail.vue";
import AssessmentsDetail from "@/pages/UserDetailPage/AssessmentsDetail.vue";
import {useGlobalStore} from "@/store";
import ChangePhoneDialog from "@/pages/UserDetailPage/ChangePhoneDialog.vue";
import ChangeEmailAddressDialog from "@/pages/UserDetailPage/ChangeEmailAddressDialog.vue";
import ChangeAccountTypeDialog from "@/pages/UserDetailPage/ChangeAccountTypeDialog/ChangeAccountTypeDialog.vue";
import EnableDisableDialog from "@/pages/UserDetailPage/DisableEnableDialog.vue";
import ResetAssessments from "@/pages/UserDetailPage/ResetAssessmentDialog.vue";
import ResetPasswordDialog from "@/pages/UserDetailPage/ResetPasswordDialog.vue";
import yapi from "@/lib/yapi";
import UpdateUserRole from "@/pages/UserDetailPage/ChangeUserRole.vue";
import {
  permissionToCheckboxState,
  checkboxStateToAbilities,
  defaultCheckBoxStates,
  PermissionCheckBoxState
} from "@/lib/permission";
import PermissionCheckboxes from "@/pages/UserDetailPage/PermissionsDetail/PermissionCheckboxes.vue";
import PermissionsList from "@/pages/UserDetailPage/PermissionsList.vue";
import TenantSearchDropdown from "@/components/FormFields/TenantSearchDropdown.vue";
import InstitutionSearchDropdown from "@/components/FormFields/InstitutionSearchDropdown.vue";
import CohortSearchDropdown from "@/components/FormFields/CohortSearchDropdown.vue";
import DownloadSubjectChoiceDialog from "@/pages/UserDetailPage/DownloadSubjectChoiceDialog.vue";
import BasicAuthCard from "@/pages/UserDetailPage/BasicAuthCard.vue";
import DownloadAssessmentDialog from "@/pages/UserDetailPage/DownloadAssessmentDialog.vue";
import DownloadCvDialog from "@/pages/UserDetailPage/DownloadCvDialog.vue";

const store = useGlobalStore();
const route = useRoute();
const showDisableEnableUser = ref(false);
const showResetAssessmentsDialog = ref(false);
const showChangeEmailDialog = ref(false);
const showChangePhoneDialog = ref(false);
const showChangeAccountTypeDialog = ref(false);
const showResetPasswordDialog = ref(false);
const showChangeRoleDialog = ref(false);
const showDownloadSubjectChoiceReportDialog = ref(false);
const showDownloadAssessmentReportDialog = ref(false);
const showDownloadCVDialog = ref(false);
const tab = ref("view");

const user = ref<DashUser>();
const isAdminOrRoot = ref(store.user?.app.role === Role.Admin || store.user?.app.role === Role.Root);
const section = ref("profile");

const fullName = computed(() => {
  if (!user.value) return "";
  return `${user.value.profile.firstName} ${user.value.profile.lastName}`;
});

const selectedTenant = ref<Tenant>();
const selectedInstitution = ref<Institution>();
const selectedCohort = ref<Cohort>();
const checkboxesState: Ref<PermissionCheckBoxState> = ref({});

async function fetchUser() {
  try {
    store.networkBusy = true;
    user.value = (await yapi.admin.user.get(route.params.id as string)).data;
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
  }
}

watch([selectedTenant, selectedInstitution, selectedCohort], async ([tenant, institution, cohort]) => {
  const entityId = cohort?._id || institution?._id || tenant?._id;
  const entity: Entity = getEntityOfSelected() as Entity;

  if (entityId) {
    const permission = getUserPermission(entityId);
    checkboxesState.value = permission
      ? permissionToCheckboxState(permission) : defaultCheckBoxStates[entity] as PermissionCheckBoxState;
  }
});

function getUserPermission(entityId: string): Permission | undefined {
  if (!user.value) return undefined;
  return user.value.app.permissions.find((p) => p.entityId === entityId);
}

const getSelectedEntityTitle = computed(() => {
  let title = "";
  if (selectedCohort.value) {
    title = selectedCohort.value.title || "";
  } else if (selectedInstitution.value) {
    title = selectedInstitution.value.title || "";
  } else if (selectedTenant.value) {
    title = selectedTenant.value.title || "";
  }
  return title;
});

function getEntityOfSelected(): Entity | null {
  if (selectedCohort.value) {
    return Entity.Cohort;
  } else if (selectedInstitution.value) {
    return Entity.Institution;
  } else if (selectedTenant.value) {
    return Entity.Tenant;
  } else {
    return null;
  }
}

async function confirmPermission(): Promise<void> {
  if (store.networkBusy) {
    store.showSnackbar("Please wait for the previous request to finish");
    return;
  }

  const entity = getEntityOfSelected();
  if (!entity) return;
  const entityId = selectedCohort.value?._id || selectedInstitution.value?._id || selectedTenant.value?._id;

  const permission: Permission = {
    entity: entity,
    entityId: entityId as string,
    abilities: checkboxStateToAbilities(checkboxesState.value),
  };
  store.networkBusy = true;
  await setPermission(permission);
  store.networkBusy = false;
  store.showSnackbar(`Permissions for ${getSelectedEntityTitle.value} updated`);
}

async function setPermission(permission: Permission) {
  const res = await yapi.admin.user.setPermission(
    user.value?._id as string,
    permission,
  );
  user.value = res.data.user;
}

onMounted(async () => {
  await fetchUser();
});

async function onCloseChangeEmailDialog() {
  showChangeEmailDialog.value = false;
  await fetchUser();
}

async function onDisableEnableDialog() {
  showDisableEnableUser.value = false;
  await fetchUser();
}

const onCloseResetAssessmentDialog = async () => {
  showResetAssessmentsDialog.value = false;
  await fetchUser();
};

const onCloseChangePhoneDialog = () => {
  showChangePhoneDialog.value = false;
  fetchUser();
};

const onCloseChangeAccountTypeDialog = () => {
  showChangeAccountTypeDialog.value = false;
  fetchUser();
};

const canEditAnyUsers = computed(() => store.hasAbility(Entity.User, Action.edit));
</script>
