<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12">
          <wide-trait-score-card
            :title="$t(`translations.assessments.managing_emotion.title`)"
            :result="parseInt(String(managingEmotionScore.total_score * 100))"
            :colour="managingEmotionColour"
          />
        </v-col>
        <v-col cols="12">
          <wide-trait-score-card
            :title="$t(`translations.assessments.understanding_emotion.title`)"
            :result="parseInt(String(understandingEmotionScore?.total_score * 100))"
            :colour="understandingEmotionColour"
          />
        </v-col>
        <v-col cols="12">
          <wide-trait-score-card
            :title="$t(`translations.assessments.perceiving_emotion.title`)"
            :result="parseInt(String(perceivingEmotionScore.total_score * 100))"
            :colour="perceivingEmotionColour"
          />
        </v-col>
      </v-row>
    </v-col>
    <v-col>
      {{  $t("common.coming_soon_label")}}
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import WideTraitScoreCard from "@/pages/UserDetailPage/AsessmentsDetail/WideTraitScoreCard.vue";
import {ManagingEmotionResult, PerceivingEmotionResult, UnderstandingEmotionResult} from "@YenzaCT/sdk";
import {PropType} from "vue";

defineProps({
  managingEmotionScore: {
    type: Object as PropType<ManagingEmotionResult>,
    required: true
  },
  understandingEmotionScore: {
    type: Object as PropType<UnderstandingEmotionResult>,
    required: true
  },
  perceivingEmotionScore: {
    type: Object as PropType<PerceivingEmotionResult>,
    required: true
  }
});

const managingEmotionColour = "green";
const understandingEmotionColour = "blue";
const perceivingEmotionColour = "red";
</script>

<style scoped>
</style>
