<template>
  <v-card>
    <v-card-title>{{ $t("components.ResetCohortAssessments.title") }}</v-card-title>
    <v-card-text>
      <p class="text-caption">{{ $t("components.ResetCohortAssessments.instruction") }}<b>{{ cohortTitle }}</b>.</p>
      <v-checkbox
        density="compact"
        :hide-details="true"
        v-model="selectAll"
        :label="$t('components.ResetCohortAssessments.label.select_all')"
        @change="selectAllAssessments"
      />
      <v-checkbox
        density="compact"
        :hide-details="true"
        v-for="(value, key) in selectedItems"
        :key="key"
        v-model="selectedItems[key]"
        :label="$t(`assessments.${key}.title`)"
        :value="true"
      ></v-checkbox>

      <v-divider class="my-4" />
      <v-row>
        <v-col cols="12">
          <span class="text-red font-weight-bold">{{ $t("components.ResetAssessmentDialog.warning_label") }} </span>
          <span>{{ $t("components.ResetAssessmentDialog.action_label") }}</span>
          <v-text-field
            v-model="verifyString"
            density="compact"
            variant="outlined"
            :label="$t('components.CohortDetailPage.proceed_instruction')
              + ': '
              + `${cohortTitle}`"
            class="mt-2"
          />
        </v-col>
      </v-row>
      <v-divider class="mb-4" />

    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="submitForm" :disabled="!hasSelectedAssessment">
        {{ $t("buttons.confirm") }}
      </v-btn>
      <v-spacer/>
      <v-btn
        color="secondary"
        @click="$emit('close')"
      >
        {{ $t("buttons.cancel") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import {PropType, reactive, watch, computed, ref} from "vue";
import { isYapiError, YapiError, AssessmentType} from "@YenzaCT/sdk";
import yapi from "@/lib/yapi";
import {useGlobalStore} from "@/store";
import {useI18n} from "vue-i18n";

const {t} = useI18n();
const store = useGlobalStore();
const error = ref<string | undefined>("");
const verifyString = ref("");
let selectAll = false;

const emits = defineEmits<{
  (e: "close"): void;
}
>();

const props = defineProps({
  cohortTitle: {
    type: String as PropType<string>,
    required: true
  },
  cohortId: {
    type: String as PropType<string>,
    required: true
  }
});

const selectedItems = reactive<{ [key: string]: boolean }>({
  [AssessmentType.HollandCode]: false,
  [AssessmentType.Big5]: false,
  [AssessmentType.LearningStyles]: false,
  [AssessmentType.ManagingEmotion]: false,
  [AssessmentType.PerceivingEmotion]: false,
  [AssessmentType.UnderstandingEmotion]: false,
  [AssessmentType.Maze]: false,
  [AssessmentType.RockPaperScissors]: false,
});

const hasSelectedAssessment = computed(() =>
  Object.values(selectedItems).some((value) => value) && (verifyString.value === props.cohortTitle));

function selectAllAssessments() {
  if (selectAll) {
    for (const key in selectedItems) {
      selectedItems[key] = true;
    }
  } else {
    for (const key in selectedItems) {
      selectedItems[key] = false;
    }
  }
}

async function submitForm() {
  // Implement your form submission logic here
  const selectedAssessments = Object.keys(selectedItems).filter(
    (key) => selectedItems[key]
  );

  try {
    await yapi.admin.cohort.resetAssessments(props.cohortId, selectedAssessments);
    emits("close");
    store.showSnackbar(t("components.ResetAssessmentDialog.snackbar_message"));
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
      error.value = yError.response?.data.message;
    } else {
      throw e;
    }
  }
}

// Watch for changes in individual checkboxes, and if any checkbox is unchecked, uncheck the "Select All" checkbox.
watch(selectedItems, (newVal) => {
  selectAll = Object.values(newVal).every((value) => value);
});
</script>

<style scoped>

</style>
