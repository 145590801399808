<template>
  <v-card color="#F5F5F8">

    <v-card-text>
      <v-row>
        <v-col cols="12" class="text-center">
          <h4>
            {{ title }}
          </h4>
          <span class="text-caption">
            {{ totalRoundedScore }} / 100
          </span>
        </v-col>
      </v-row>
      <v-row dense no-gutters>
        <v-col class="text-left learning-styles-summary-metrics-metric-gauge-label font-weight-bold">
          {{ leftLabel }}
        </v-col>
        <v-col class="text-right  learning-styles-summary-metrics-metric-gauge-label font-weight-bold">
          {{ rightLabel }}
        </v-col>
      </v-row>

      <v-row :dense="true" :no-gutters="true">
        <v-col>
          <div class="learning-styles-summary-metrics-metric-gauge">
            <div class="learning-styles-summary-metrics-metric-gauge-progress float-left">
              <v-progress-linear
                :color="colour"
                height="10px"
                :model-value="score < 0 ? (score * - 10) : score === 0 ? 2 : 0"
                class=" rounded-tl rounded-bl justify-start"
                :reverse="true"
              />
            </div>
            <div :class="`bg-${colour}`" class="learning-styles-summary-metrics-metric-gauge-line float-left" />

            <div class="learning-styles-summary-metrics-metric-gauge-progress float-left">
              <v-progress-linear
                class="rounded-tr rounded-br"
                :color="colour"
                height="10px"
                :model-value="score > 0 ? score*10 : score === 0 ? 2 : 0"
              />
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row dense no-gutters class="justify-space-between">
        <div class="d-flex flex-row justify-start learning-styles-summary-metrics-labels-label text-caption text-left">
          {{ $t("translations.assessments.learning_styles.high") }}
        </div>
        <div class="d-flex flex-row learning-styles-summary-metrics-labels-label text-caption">
          {{ $t("translations.assessments.learning_styles.moderate") }}
        </div>
        <div class="d-flex flex-row learning-styles-summary-metrics-labels-label text-caption">
          {{ $t("translations.assessments.learning_styles.balanced") }}
        </div>
        <div class="d-flex flex-row learning-styles-summary-metrics-labels-label text-caption">
          {{ $t("translations.assessments.learning_styles.moderate") }}
        </div>
        <div class="d-flex flex-row learning-styles-summary-metrics-labels-label text-caption">
          {{ $t("translations.assessments.learning_styles.high") }}
        </div>
      </v-row>

    </v-card-text>
  </v-card>
</template>

<script lang="ts" setup>
import {PropType} from "vue";

const props = defineProps({
  title: {
    type: String as PropType<string>,
    required: true
  },
  leftLabel: {
    type: String as PropType<string>,
    required: true
  },
  rightLabel: {
    type: String as PropType<string>,
    required: true
  },
  colour: {
    type: String as PropType<string>,
    required: true
  },
  sVGIcon: {
    type: String as PropType<string>
  },
  score: {
    type: Number as PropType<number>,
    required: true
  }
});
const totalRoundedScore = Math.round(Math.abs(props.score));
</script>

<style scoped>

.sVGIcon {
  height: 15px;
}

.learning-styles-summary-metrics-metric-gauge-progress {
  width: calc(50% - 3px);
  margin-top: 10px;
}

.learning-styles-summary-metrics-metric-gauge-line {
  width: 2px;
  height: 20px;
  background: #333;
}

</style>
