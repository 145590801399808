<script setup lang="ts">
import {PropType} from "vue";

defineProps(
  {
    formats: {
      type: Array as PropType<string[]>,
      default: (() => ["csv"])
    }
  }
);

const emit = defineEmits<{
  (e: "click", format: string): void
}>();

function handleClick(format: string) {
  emit("click", format);
}
</script>

<template>
  <v-menu>
    <template v-slot:activator="{ props }">
      <v-btn
        dark
        v-bind="props"
        prepend-icon="far fa-arrow-up-from-bracket"
        class="mr-4 bg-primary"
        color="white"
      >
        {{ $t("buttons.export") }}
      </v-btn>
    </template>
    <v-list>
      <v-list-item v-if="formats.includes('csv')" @click="handleClick('csv')">
        <v-list-item-title>{{ $t("buttons.export_to_csv") }}</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="formats.includes('excel')" @click="handleClick('excel')">
        <v-list-item-title>{{ $t("buttons.export_to_excel") }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<style scoped>

</style>
