<template>
  <authenticated-layout>
    <template #headerIcon>
      far fa-cogs
    </template>
    <template #header>
      {{ $t("components.UserSettingsPage.title") }}
    </template>

    <v-container fluid class="py-4">
      <v-row>
        <v-col cols="12">
          <v-card height="100%">
            <template #title>{{ user.profile.firstName }} {{ user.profile.lastName }}</template>
            <template #append>
              <v-chip>{{ $t(`roles.${user.app.role}`) }}</v-chip>
            </template>
            <template #subtitle> {{ user.email }} <span v-if="user.phone">| {{ user.phone }}</span></template>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card :title="$t('components.UserSettingsPage.preferences')">
            <v-card-text>
              <v-row>
                <v-col cols="6">
                  <v-select
                    variant="outlined"
                    density="compact"
                    prepend-icon="far fa-palette"
                    type="text"
                    v-model="currentTheme"
                    :label="$t('components.UserSettingsPage.theme_label')"
                    :items="themeItems"
                  />
                </v-col>
                <v-col>
                  <v-select
                    variant="outlined"
                    density="compact"
                    prepend-icon="far fa-language"
                    v-model="currentLocale"
                    :label="$t('components.UserSettingsPage.language')"
                    :items="localeItems"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card title="Security">
            <v-card-text>
              <h3>{{ $t("components.UserSettingsPage.change_password")}}</h3>
              <p>{{ $t("components.UserSettingsPage.change_password_info")}}</p>
              <div class="my-4">
                <change-password :user-id="userId"/>
              </div>
              <v-divider class="my-4"/>

              <h3>{{ $t("components.UserSettingsPage.two_factor_auth") }}</h3>
              <p>{{ $t("components.UserSettingsPage.two_factor_auth_warning") }}</p>
              <div class="my-4">
                <two-factor-auth/>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </authenticated-layout>
</template>

<script setup lang="ts">
import {useI18n} from "vue-i18n";

import AuthenticatedLayout from "@/layout/AuthenticatedLayout.vue";
import {useUserSettings} from "@/lib/userSettings";
import TwoFactorAuth from "@/pages/UserSettingsPage/TwoFactorAuth.vue";
import {useGlobalStore} from "@/store";
import {computed} from "vue";
import {FrontendUser} from "@YenzaCT/sdk";
import ChangePassword from "@/pages/UserSettingsPage/ChangePassword.vue";

const {
  currentLocale,
  currentTheme,
  localeItems,
  themeItems
} = useUserSettings();
const {t} = useI18n();
const store = useGlobalStore();
const dark = t("components.UserSettingsPage.dark");
const light = t("components.UserSettingsPage.light");

// Iterate through themeItems and translate the titles
themeItems.forEach((item) => {
  if (item.title === "Dark") {
    item.title = dark;
  } else if (item.title === "Light") {
    item.title = light;
  }
});
const user = computed(() => store.user as FrontendUser);
const userId = user.value._id;
</script>
