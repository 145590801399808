<template>
  <v-card>
    <v-card-title>
      {{ $t('components.ChangeAccountTypeDialog.title') }}
    </v-card-title>

    <v-card-text>
      {{ $t('components.ChangeAccountTypeDialog.label') }}
      <span class="font-weight-bold">
        {{ $t(`accountTypes.${currentAccountType}`) }}
      </span>

      <v-select
        v-model="accountType"
        :items="accountTypes"
        item-title="title"
        item-value="value"
        variant="outlined" density="compact"
        :label="$t('components.ChangeAccountTypeDialog.label')"
        :no-data-text="$t('components.ChangeAccountTypeDialog.label')"
        @update:model-value="handleAccountTypeSelect"
      ></v-select>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn
        color="secondary"
        @click="$emit('close')"
      >
        {{ $t("buttons.cancel") }}
      </v-btn>
      <v-btn
        color="primary"
        @click="changeAccountType"
      >
        {{ $t("buttons.confirm") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import {PropType, ref, computed} from "vue";
import {isYapiError, DashUser, AccountType, YapiError, User} from "@YenzaCT/sdk";
import {useI18n} from "vue-i18n";
import {useGlobalStore} from "@/store";
import yapi from "@/lib/yapi";

const store = useGlobalStore();
const {t} = useI18n();

const props = defineProps({
  user: {
    type: Object as PropType<DashUser>,
    required: true
  }
});
const user = computed(() => props.user as DashUser);
const accountType = ref<AccountType>();
const selectedAccountType = ref<AccountType>();

const accountTypes = ref(Object.values(AccountType)
  .map((slug) => ({
    title: t(`accountTypes.${slug}`),
    value: slug
  })));
const currentAccountType = ref(user.value.app.accountType);

function handleAccountTypeSelect(accountType: AccountType) {
  selectedAccountType.value = accountType;
}

const emit = defineEmits<{
(e: "confirm"): void;
(e: "close"): void;
}
>();

const changeAccountType = async () => {
  try {
    if (selectedAccountType.value && user.value._id) {
      user.value.app.accountType = selectedAccountType.value;
      await yapi.admin.user.edit(user.value._id, user.value as User);
    }
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
    emit("close");
  }
};
</script>
