<template>
  <v-expansion-panel data-pw="favouriteCareersPanel">
    <v-expansion-panel-title>
      {{ $t("components.FavouritedCareersPanel.expansion_panel_title") }}
    </v-expansion-panel-title>
    <v-expansion-panel-text>
      <div v-if="careerData.length">
        <div v-for="career in careerData" :key="career.id" class="mb-3">
          <a :href="`${app}/careers/${career.slug}/${career.id}`" target="_blank">
            <v-btn
              class="styled-btn"
              variant="text"
              small
              color="primary"
            >{{ career.title }}</v-btn>
          </a>
        </div>
      </div>
      <div v-else>{{ $t("components.FavouritedCareersPanel.no_careers_available") }}</div>
    </v-expansion-panel-text>
  </v-expansion-panel>
</template>

<script setup lang="ts">
import yapi from "@/lib/yapi";
import { useGlobalStore } from "@/store";
import { isYapiError, YapiError } from "@YenzaCT/sdk";
import { PropType, ref, onMounted } from "vue";
import { AxiosResponse } from "axios";

interface CareerItem {
  _id: string;
  slug: { current: string };
  title: string;
}

interface CareerResponse {
  careers: CareerItem[];
}

const app = import.meta.env.VITE_APP_URL;
const store = useGlobalStore();
const careerData = ref<Array<{id: string, title: string, slug: string}>>([]);
const props = defineProps({
  careers: {
    type: Array as PropType<string[]>,
    required: true,
  },
});

async function fetchCareerTitles() {
  try {
    store.networkBusy = true;

    const fetchPromises = props.careers.map((careerId) => yapi.career.get(careerId) as unknown as Promise<AxiosResponse<CareerResponse>>);

    // Wait for all promises to resolve
    const careerDataArray = await Promise.all(fetchPromises);

    careerData.value = careerDataArray.map(
      (careerResponse) => ({
        id: careerResponse.data.careers[0]._id,
        title: careerResponse.data.careers[0].title,
        slug: careerResponse.data.careers[0].slug.current
      })
    );
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
  }
}
onMounted(fetchCareerTitles);
</script>

