import {createRouter, createWebHistory, RouteRecordRaw} from "vue-router";
import LoginView from "@/pages/LoginPage.vue";
import ForgotPasswordPage from "@/pages/ForgotPasswordPage.vue";
import HelpPage from "@/pages/HelpPage.vue";
import DashboardPage from "@/pages/DashboardPage.vue";
import UserTablePage from "@/pages/UserTablePage.vue";
import UserDetailPage from "@/pages/UserDetailPage.vue";
import TenantTablePage from "@/pages/TenantTablePage.vue";
import CouponTablePage from "@/pages/CouponTablePage.vue";
import AccessCodeTablePage from "@/pages/AccessCodeTablePage.vue";
import AccessCodeDetailPage from "@/pages/AccessCodeDetailPage.vue";
import CouponDetailPage from "@/pages/CouponDetailPage.vue";
import TenantDetailPage from "@/pages/TenantDetailPage.vue";
import {useGlobalStore} from "@/store";
import InstitutionTablePage from "@/pages/InstitutionTablePage.vue";
import InstitutionDetailPage from "@/pages/InstitutionDetailPage.vue";
import CohortDetailPage from "@/pages/CohortDetailPage.vue";
import ReportsPage from "@/pages/ReportsPage.vue";
import ReportsDetailPage from "@/pages/ReportsDetailPage.vue";
import CohortTablePage from "@/pages/CohortTablePage.vue";
import UserSettingsPage from "@/pages/UserSettingsPage.vue";
import AppConfigurationPage from "@/pages/AppConfigurationPage.vue";
import GeneralConfigForm from "@/pages/AppConfigurationPage/GeneralConfigForm.vue";
import OnboardingConfigSection from "@/pages/AppConfigurationPage/OnboardingConfigSection.vue";
import AuthMethodsConfigForm from "@/pages/AppConfigurationPage/AuthMethodsConfigForm.vue";
import LocalisationConfigForm from "@/pages/AppConfigurationPage/LocalisationConfigForm.vue";
import FeatureConfigForm from "@/pages/AppConfigurationPage/FeatureConfigSection.vue";
import IntegrationsConfigForm from "@/pages/AppConfigurationPage/IntegrationsConfigForm.vue";
import ThemeConfigSection from "@/pages/AppConfigurationPage/ThemeConfigSection.vue";

const routes: RouteRecordRaw[] = [
  {
    path: "/login",
    name: "login",
    component: LoginView
  },
  {
    path: "/forgot-password/request",
    name: "forgotPassword",
    component: ForgotPasswordPage
  },
  {
    path: "/",
    name: "dashboard",
    component: DashboardPage
  },
  {
    path: "/configuration",
    name: "configuration",
    redirect: "/configuration/general",
    component: AppConfigurationPage,
    children: [
      {
        name: "generalConfiguration",
        path: "general",
        component: GeneralConfigForm
      },
      {
        name: "onboardingConfiguration",
        path: "onboarding",
        component: OnboardingConfigSection
      },
      {
        name: "authenticationConfiguration",
        path: "authentication",
        component: AuthMethodsConfigForm
      },
      {
        name: "localisationConfiguration",
        path: "localisation",
        component: LocalisationConfigForm
      },
      {
        name: "featureConfiguration",
        path: "features",
        component: FeatureConfigForm
      },
      {
        name: "themeConfiguration",
        path: "theme",
        component: ThemeConfigSection
      },
      {
        name: "integrationConfiguration",
        path: "integrations",
        component: IntegrationsConfigForm
      }
    ]
  },
  {
    path: "/user-settings",
    name: "userSettings",
    component: UserSettingsPage
  },
  {
    path: "/help",
    name: "help",
    component: HelpPage
  },
  {
    path: "/users",
    name: "users",
    // component: UserBrowserPage
    component: UserTablePage
  },
  {
    path: "/users-detail/:id",
    name: "userDetail",
    component: UserDetailPage
  },
  {
    path: "/tenants",
    name: "tenants",
    component: TenantTablePage
  },
  {
    path: "/tenants/:id",
    name: "tenantDetail",
    component: TenantDetailPage
  },
  {
    path: "/institutions",
    name: "institutions",
    component: InstitutionTablePage
  },
  {
    path: "/institutions/:id/:section?",
    name: "institutionDetail",
    component: InstitutionDetailPage
  },
  {
    path: "/cohorts",
    name: "cohorts",
    component: CohortTablePage
  },
  {
    path: "/cohorts/:id",
    name: "cohortDetail",
    component: CohortDetailPage
  },
  {
    path: "/coupons",
    name: "coupons",
    component: CouponTablePage
  },
  {
    path: "/coupons/:id",
    name: "couponDetail",
    component: CouponDetailPage
  },
  {
    path: "/vouchers",
    name: "vouchers",
    component: AccessCodeTablePage
  },
  {
    path: "/access-code/:id",
    name: "accessCode",
    component: AccessCodeDetailPage
  },
  {
    path: "/insights",
    name: "reports",
    component: ReportsPage
  },
  {
    path: "/insights/:report",
    name: "reportsDetail",
    component: ReportsDetailPage
  },
];

const router = createRouter({
  history: createWebHistory("/"),
  routes,
});

router.beforeEach((to, from, next) => {
  const store = useGlobalStore();
  if (to.name === "login" && store.user !== null) next({name: "dashboard"});
  else next();
});

export default router;
