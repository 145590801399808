<template>
  <v-alert type="info" v-if="permissions.length === 0">
    {{ $t('components.PermissionsList.alert_message') }}
  </v-alert>
  <v-card density="compact">

    <v-row>
      <v-expansion-panels variant="accordion">
        <v-expansion-panel v-for="permission in permissions" :key="permission.entityId">
          <entity-permission :permission="permission"/>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-card>
</template>

<script setup lang="ts">
import {Cohort, Entity, Institution, Permission, Tenant, DashUser} from "@YenzaCT/sdk";
import {onMounted, PropType, ref} from "vue";
import yapi from "@/lib/yapi";
import EntityPermission from "@/components/PermissionsList/EntityPermission.vue";

const props = defineProps({
  permissions: {
    type: Array as PropType<Permission[]>,
    required: true,
  },
});
const entities = ref<(Tenant | Institution | Cohort | DashUser)[]>([]);

onMounted(async () => {
  const responses = await Promise.all(props.permissions.map((p) => {
    if (p.entityId === Entity.Tenant) {
      return yapi.admin.tenant.get(p.entityId);
    } else if (p.entityId === Entity.Institution) {
      return yapi.admin.institution.get(p.entityId);
    } else if (p.entityId === Entity.Cohort) {
      return yapi.admin.cohort.get(p.entityId);
    } else if (p.entityId === Entity.User) {
      return yapi.admin.user.get(p.entityId);
    } else {
      return Promise.reject(new Error(`Unknown entity: ${p.entityId}`));
    }
  }));
  entities.value = responses.map((r) => r.data);
});
</script>
