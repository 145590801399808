<template>
  <report-filter-options
    :show-tenant-filter="true"
    :show-institution-filter="true"
    :show-cohort-filter="true"
    :show-cohort-breakdown="false"
    :is-report-active="isReportActive"
    :disable-run-report-button="!curriculum "
    @change-tenant="onTenantChange"
    @change-institution="onInstitutionSelectionChange"
    @change-cohort="onCohortChange"
    @click-run-report="runReport"
    @click-reset="resetReport"
  >
    <template #filters>
      <v-col>
        <CurriculumSelectDropdown v-model="curriculum" @change="onCurriculumChange"/>
      </v-col>
    </template>

    <template #filterList>
      <v-list-item>
        <span class="font-weight-bold">{{  $t('common.curriculum') }}: </span> {{ $t(`curriculums.${curriculum}.title`) }}
      </v-list-item>
    </template>
  </report-filter-options>

  <v-alert v-if="isReportActive && !reportDataValue">
    {{ $t("components.SubjectChoiceDistributionReport.no_data") }}
  </v-alert>

  <div v-if="loadingData">
    <v-row>
      <v-col>
        <v-skeleton-loader type="card"/>
      </v-col>
      <v-col>
        <v-skeleton-loader type="card"/>
      </v-col>
      <v-col>
        <v-skeleton-loader type="card"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-skeleton-loader type="card"/>
      </v-col>
    </v-row>
  </div>
  <subject-distribution-graphs v-if="isReportActive && reportDataValue && curriculum && !loadingData"
                               :report-data="reportDataValue"
                               :curriculum="activeCurriculum" class="mt-6"/>

</template>

<script setup lang="ts">
import {useInsights} from "@/pages/ReportsDetailPage/reportsDetailPage";
import {computed} from "vue";

import ReportFilterOptions from "@/components/Reporting/ReportFilterOptions.vue";
import CurriculumSelectDropdown from "@/components/FormFields/CurriculumSelectDropdown.vue";
import SubjectDistributionGraphs from "@/pages/ReportsDetailPage/components/SubjectDistributionGraphs.vue";

const {
  timeFilter,
  tenantId,
  institutionIds,
  cohortId,
  curriculum,
  loadingData,
  isReportActive,
  breakdownByCohort,
  reportData,
  getReportData,
  onTenantChange,
  onCurriculumChange,
  onCohortChange,
  onInstitutionSelectionChange
} = useInsights();

const reportDataValue = computed(() => reportData.value ? reportData.value[0] : null);

let activeCurriculum = "";

function runReport() {
  if (curriculum.value)
    activeCurriculum = curriculum.value;

  getReportData("subject-choice-distribution", {
    institutionIds: institutionIds.value,
    tenantId: tenantId.value,
    cohortId: cohortId.value,
    breakdownByCohort: breakdownByCohort.value,
    curriculum: curriculum.value,
    time: timeFilter.value
  });
}

function resetReport() {
  reportData.value = [];
  curriculum.value = undefined;
  isReportActive.value = false;
  institutionIds.value = [];
  tenantId.value = undefined;
  cohortId.value = undefined;
}

</script>
