<template>
  <v-card :title="$t('common.details')" height="100%" data-pw="userDetailsCard">
    <v-card-text>
      <v-table>
        <tbody>
          <detail-row
            :label="$t('components.ProfileCard.email')"
            icon="far fa-envelope"
            :value="props.user?.email"
            @click="openUserDetails"
            data-pw="userEmailRow"
          />
          <detail-row
            :label="$t('components.ProfileCard.phone')"
            icon="far fa-phone"
            :value="props.user?.phone"
            @click="openUserDetails"
            data-pw="userPhoneRow"
          />
          <detail-row
            :label="$t('components.ProfileCard.account_type')"
            icon="far fa-user"
            :value="$t(`accountTypes.${props.user?.app.accountType}`)"
            data-pw="userAccountTypeRow"
          />
          <detail-row
            :label="$t('components.ProfileCard.role')"
            icon="far fa-signal"
            :value="$t(`roles.${props.user?.app.role}`)"
            data-pw="userRoleRow"
          />
          <access-code-detail-row v-if="props.user?._id" :user-id="props.user?._id" />
          <detail-row
            v-if="canReadCohort"
            :label="$t('components.ProfileCard.cohort')"
            icon="far fa-building"
            :value="cohortData?.title ?? $t('common.none')"
            @click="cohortData?._id ?
              router.push({
                name: 'cohortDetail',
                params: {
                  institutionId: institutionData?._id,
                  id: cohortData?._id,
                },
              }) : null
            "
            data-pw="userCohortRow"
          />
          <detail-row
            v-if="canReadInstitution"
            :label="$t('components.ProfileCard.institution')"
            icon="far fa-building"
            :value="institutionData?.title ?? $t('common.none')"
            @click="institutionData?._id ?
              router.push({
                name: 'institutionDetail',
                params: { id: institutionData?._id },
              }) : null
            "
            data-pw="userInstitutionRow"
          />
          <detail-row
            v-if="canReadTenant"
            :label="$t('components.ProfileCard.tenant')"
            icon="far fa-buildings"
            :value="tenantData?.title ?? $t('common.none')"
            @click="tenantData?._id ?
              router.push({ name: 'tenantDetail', params: { id: tenantData?._id } }) : null
            "
            data-pw="userTenantRow"
          />
        </tbody>
      </v-table>
    </v-card-text>
  </v-card>
</template>

<script lang="ts" setup>
import {computed, onMounted, PropType, ref} from "vue";
import DetailRow from "@/components/DetailRow.vue";
import {
  Action,
  Cohort,
  Entity,
  Institution,
  isYapiError,
  Role,
  Tenant,
  DashUser,
  YapiError
} from "@YenzaCT/sdk";
import yapi from "@/lib/yapi";
import {useGlobalStore} from "@/store";
import {useRouter} from "vue-router";
import AccessCodeDetailRow from "@/pages/UserDetailPage/AccessCodeDetailRow.vue";

const router = useRouter();
const store = useGlobalStore();
const props = defineProps({
  accountType: {
    type: String as PropType<string>,
    required: true,
  },
  role: {
    type: String as PropType<string>,
    required: true,
  },
  user: {
    type: Object as PropType<DashUser>,
    required: true,
  }
});

const cohortData = ref<Cohort>();
const tenantData = ref<Tenant>();
const institutionData = ref<Institution>();

const isAdminOrRoot = ref(store.user?.app.role === Role.Admin || store.user?.app.role === Role.Root);

const canReadEntity = (entity: Entity, subEntity: Entity) => {
  const userEntityValue = props.user?.app[entity.toLowerCase() as "cohort" | "institution" | "tenant"];
  if (!userEntityValue) return false;

  return isAdminOrRoot.value || store.canAccessSubEntityViaEntity(
    entity,
    subEntity,
    Action.read,
    userEntityValue
  ) || store.canAccessSubEntityViaEntity(
    subEntity,
    subEntity,
    Action.read,
    userEntityValue
  );
};

const canReadTenant = computed(() => canReadEntity(Entity.Tenant, Entity.Tenant));
const canReadInstitution = computed(() => canReadEntity(Entity.Tenant, Entity.Institution));
const canReadCohort = computed(() =>
  canReadEntity(Entity.Tenant, Entity.Cohort) || canReadEntity(Entity.Institution, Entity.Cohort));

async function fetchDetails() {
  try {
    store.networkBusy = true;

    if (props.user?.app.cohort && canReadCohort.value) {
      cohortData.value = (
        await yapi.admin.cohort.get(props.user?.app?.cohort)
      ).data;
    }

    if (props.user?.app.tenant && canReadTenant.value) {
      tenantData.value = (
        await yapi.admin.tenant.get(props.user?.app.tenant.toString())
      ).data;
    }

    if (props.user?.app.institution && canReadInstitution.value) {
      institutionData.value = (
        await yapi.admin.institution.get(props.user?.app.institution.toString())
      ).data;
    }
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
  }
}

onMounted(async () => {
  await fetchDetails();
});

const openUserDetails = () => {
  router.push({
    name: "userDetail",
    params: {id: props.user?._id}
  });
};
</script>
