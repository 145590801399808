<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title>
        <div class="text-h5">{{ $t('coupon.delete_coupon') }}</div>
      </v-card-title>
      <v-card-text>
        {{  $t('coupon.delete_message') }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" @click="dialog = false">
          {{ $t("buttons.cancel") }}
        </v-btn>
        <v-btn color="red darken-1" @click="deleteConfirmed">
          {{ $t("buttons.delete") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import {PropType, ref, watch} from "vue";

const props = defineProps({
  modelValue: Boolean as PropType<boolean>,
});

const dialog = ref(props.modelValue);
const emit = defineEmits(["update:modelValue", "delete"]);

watch(() => props.modelValue, (newValue) => {
  dialog.value = newValue;
});

watch(() => dialog.value, (newValue) => {
  emit("update:modelValue", newValue);
});

function deleteConfirmed() {
  dialog.value = false;
  emit("delete");
}
</script>

