<script setup lang="ts">
import ConfigurationSectionRow from "@/pages/AppConfigurationPage/ConfigurationSectionRow.vue";
import {computed, ref, onMounted} from "vue";
import {useGlobalStore} from "@/store";
import {isYapiError, YapiError} from "@YenzaCT/sdk";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import {isEqual} from "lodash";

const store = useGlobalStore();

const settings = ref({
  singleLogoutUrl: ""
});

const changed = computed(
  () => !isEqual(store.appConfiguration.authSettings.azureAdb2c, settings.value)
);

const confirmVisible = ref(false);

onMounted(() => {
  reset();
});

async function save() {
  try {
    const authSettings = store.appConfiguration.authSettings;
    authSettings.azureAdb2c = settings.value;

    await store.saveAppConfiguration("authSettings", authSettings);
    store.showSnackbar("Saved policy configuration");
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    confirmVisible.value = false;
    reset();
  }
}

function reset() {
  if (store.appConfiguration.authSettings.azureAdb2c?.singleLogoutUrl)
    settings.value.singleLogoutUrl = store.appConfiguration.authSettings.azureAdb2c?.singleLogoutUrl;
}

</script>

<template>
  <v-card flat color="grey-lighten-4">
    <v-card-title>{{ $t("components.AzureADB2CConfigForm.title") }}</v-card-title>
    <v-divider class="my-2"/>
    <v-card-text>
      <configuration-section-row>
        <template #name>
          Single Signout URL
        </template>
        <template #comment>
          Redirect URL for user on signout.
        </template>
        <v-text-field
          v-model="settings.singleLogoutUrl"
          color="primary"
          label="URL"
        />
      </configuration-section-row>
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn
        @click="reset"
        color="secondary"
        :disabled="!changed"
      >
        {{ $t("buttons.reset") }}
      </v-btn>
      <v-btn
        @click="confirmVisible = true"
        color="primary"
        :disabled="!changed"
      >
        {{ $t("buttons.apply") }}
      </v-btn>
    </v-card-actions>
  </v-card>

  <confirm-dialog
    v-model="confirmVisible"
    @confirm="save"
    @cancel="confirmVisible = false"
    text="Are you sure you want to update the Azure AD B2C config?"
    title="Azure ADB2C"/>

</template>

<style scoped>

</style>
