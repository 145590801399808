<template>
  <v-card flat color="grey-lighten-4">
    <v-card-title> {{ $t("components.FeatureSelector.title") }}</v-card-title>
    <v-card-subtitle>These features can be enabled and disabled across all packages</v-card-subtitle>
    <v-divider class="my-2"/>
    <v-card-text>
      <configuration-section-row :dense="true" v-for="key in Object.keys(features)" :key="key">
        <template #name>
          {{ $t(`components.FeatureSelector.${key}`) }}
        </template>
        <template #comment v-if="features[key]">
          <a :href="`#${key}`" v-if="individualFeatureSettings.includes(key)">View Settings</a>
        </template>
        <labeled-switch
          v-model="features[key]"
        />
      </configuration-section-row>
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn
        @click="reset"
        color="secondary"
        :disabled="!changed"
      >
        {{ $t("buttons.reset") }}
      </v-btn>
      <v-btn
        @click="confirmVisible = true"
        color="primary"
        :disabled="!changed"
      >
        {{ $t("buttons.apply") }}
      </v-btn>
    </v-card-actions>
  </v-card>

  <div v-if="features['tertiary_institution_explorer']" id="tertiary_institution_explorer" class="mt-4">
    <tertiary-institution-feature-settings/>
  </div>

  <div v-if="features['subject_choice_explorer']" id="subject_choice_explorer" class="mt-4">
    <subject-choice-navigator-config-form />
  </div>

  <div v-if="features['skills_explorer']" id="skills_explorer" class="mt-4">
    <skills-explorer-config-form />
  </div>

  <confirm-dialog
    :title="$t('common.confirm_title')"
    :text="$t('common.confirm_text')"
    v-model="confirmVisible"
    @confirm="save"
    @cancel="confirmVisible = false"
  />
</template>

<script setup lang="ts">
import {computed, ref} from "vue";
import {useGlobalStore} from "@/store";
import {isYapiError, YapiError} from "@YenzaCT/sdk";
import {isEqual} from "lodash";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import TertiaryInstitutionFeatureSettings from "@/pages/AppConfigurationPage/TertiaryInstitutionConfigForm.vue";
import {useI18n} from "vue-i18n";
import ConfigurationSectionRow from "@/pages/AppConfigurationPage/ConfigurationSectionRow.vue";
import LabeledSwitch from "@/components/LabeledSwitch.vue";
import SubjectChoiceNavigatorConfigForm from "@/pages/AppConfigurationPage/SubjectChoiceNavigatorConfigForm.vue";
import SkillsExplorerConfigForm from "@/pages/AppConfigurationPage/SkillsExplorerConfigForm.vue";

const store = useGlobalStore();
const {t} = useI18n();
const confirmVisible = ref(false);
const features = ref({...store.appConfiguration.features});

const changed = computed(
  () => !isEqual(store.appConfiguration.features, features.value)
);

const individualFeatureSettings = [
  "tertiary_institution_explorer",
  "subject_choice_explorer",
  "skills_explorer"
];

async function save() {
  try {
    await store.saveAppConfiguration("features", features.value);
    store.showSnackbar(t("components.FeatureSelector.features_updated"));
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    confirmVisible.value = false;
    reset();
  }
}

function reset() {
  features.value = {...store.appConfiguration.features};
}
</script>
