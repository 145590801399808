<template>
  <v-card :title="title">
    <v-card-text>
      <v-list>
        <v-list-item>
          <v-list-item-title>
            {{ $t('components.DisableEnableTenantDialog.warning', { action: disabled ? "enable" : "disable" }) }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ $t('components.DisableEnableTenantDialog.info') }}
          </v-list-item-subtitle>
        </v-list-item>
        <v-list-item>
          <v-text-field
            @keyup.enter="disableEnableUser"
            v-model="confirmString"
            variant="outlined"
            placeholder="CONFIRM"
            required
          />
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn
        color="secondary"
        @click="$emit('close')"
      >
        {{ $t("buttons.cancel") }}
      </v-btn>
      <v-btn
        color="primary"
        @click="disableEnableUser"
        :disabled="confirmString.toUpperCase() !== 'CONFIRM'"
      >
        {{ $t("buttons.confirm") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import {computed, ref} from "vue";
import {isYapiError, YapiError} from "@YenzaCT/sdk";
import {useRoute} from "vue-router";
import {useI18n} from "vue-i18n";
import {useGlobalStore} from "@/store";
import yapi from "@/lib/yapi";

const {t} = useI18n();
const store = useGlobalStore();

const route = useRoute();

const props = defineProps({
  disabled: {
    type: Boolean,
    required: true
  }
});

const confirmString = ref("");
const tenantId = route.params.id as string;

const emits = defineEmits<{
  (e: "close"): void;
}
>();

const title = computed(
  () => t(props.disabled ? "components.DisableEnableTenantDialog.enable_tenant" : "components.DisableEnableTenantDialog.disable_tenant")
);

const disableEnableUser = async () => {
  try {
    store.networkBusy = true;
    const res = await (
      props.disabled
        ? yapi.admin.tenant.enable(tenantId)
        : yapi.admin.tenant.disable(tenantId)
    );
    emits("close");
    store.showSnackbar(t(res.data.disabled ? "components.DisableEnableTenantDialog.tenant_enabled" : "components.DisableEnableTenantDialog.tenant_disabled"));
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
  }
};

</script>
