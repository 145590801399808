<template>
  <report-filter-options
    :show-tenant-filter="true"
    :show-institution-filter="true"
    :show-cohort-filter="true"
    :show-cohort-breakdown="true"
    :show-institution-breakdown="true"
    :allow-export="true"
    :is-report-active="isReportActive"
    :disable-run-report-button="!curriculum"
    @change-tenant="onTenantChange"
    @change-cohort-breakdown="onCohortBreakdownChange"
    @change-institution-breakdown="onInstitutionBreakdownChange"
    @change-institution="onInstitutionSelectionChange"
    @click-run-report="runReport"
    @click-export-report="exportReportData"
    @click-reset="resetReport"
  >
    <template #filters>
      <v-col>
        <curriculum-select-dropdown v-model="curriculum" @change="onCurriculumChange"/>
      </v-col>
    </template>
    <template #filterList>
      <v-list-item>
        <span class="font-weight-bold">{{ $t('common.curriculum') }}: </span> {{
          $t(`curriculums.${curriculum}.title`)
        }}
      </v-list-item>
      <v-list-item v-if="breakdownByCohort || breakdownByInstitution">
        <span class="font-weight-bold">{{ $t('common.breakdown_by') }}: </span>
        <span v-if="breakdownByInstitution">{{ $t('common.institution') }}</span>
        <span v-if="breakdownByCohort && breakdownByInstitution">, </span>
        <span v-if="breakdownByCohort">{{ $t('common.cohort') }}</span>
      </v-list-item>
    </template>
  </report-filter-options>
  <v-data-table
    :items="filteredReportData"
    :headers="headers"
    :loading="loadingData"
    :items-per-page="50"
  >
    <template v-slot:item.percentageComplete="{ item }">
      <v-chip :color="getColor(item.percentageComplete as number)">
        {{ item.percentageComplete }}%
      </v-chip>
    </template>
  </v-data-table>
</template>

<script setup lang="ts">
import {computed, ref} from "vue";
import {useI18n} from "vue-i18n";
import {useInsights} from "@/pages/ReportsDetailPage/reportsDetailPage";
import CurriculumSelectDropdown from "@/components/FormFields/CurriculumSelectDropdown.vue";
import ReportFilterOptions from "@/components/Reporting/ReportFilterOptions.vue";

const {t} = useI18n();

const dataTableCohortSearch = ref<string>("");

const showReport = ref(false);

const {
  timeFilter,
  tenantId,
  institutionIds,
  curriculum,
  isReportActive,
  breakdownByCohort,
  breakdownByInstitution,
  reportData,
  loadingData,
  getColor,
  getReportData,
  exportData,
  onTenantChange,
  onCurriculumChange,
  onInstitutionSelectionChange,
  onInstitutionBreakdownChange,
  onCohortBreakdownChange
} = useInsights();

/**
 * Filters the data table by the cohort search
 */
const filteredReportData = computed(() => {
  if (!dataTableCohortSearch.value) {
    return reportData.value;
  } else {
    return reportData.value
      .filter((item) => "cohort" in item && typeof item.cohort === "object" && item.cohort !== null)
      .filter((item) =>
        (item.cohort as { title: string }).title.toLowerCase().includes(dataTableCohortSearch.value.toLowerCase()));
  }
});

const headers = computed(
  () => {
    const headers = [
      {
        title: t("components.SubjectSelectionCompletionReport.headers.tenant"),
        key: "tenant.title"
      }
    ];

    if (breakdownByInstitution.value)
      headers.push({
        title: t("components.SubjectSelectionCompletionReport.headers.institution"),
        key: "institution.title"
      },);

    if (breakdownByCohort.value)
      headers.push({
        title: t("components.SubjectSelectionCompletionReport.headers.cohort"),
        key: "cohort.title"
      });

    headers.push({
      title: t("components.SubjectSelectionCompletionReport.headers.percentage_complete"),
      key: "percentageComplete"
    },
    {
      title: t("components.SubjectSelectionCompletionReport.headers.complete"),
      key: "complete"
    },
    {
      title: t("components.SubjectSelectionCompletionReport.headers.incomplete"),
      key: "incomplete"
    },
    {
      title: t("components.SubjectSelectionCompletionReport.headers.total"),
      key: "total"
    });

    return headers;
  }
);

function runReport() {
  showReport.value = true;

  getReportData("subject-choice-completion", {
    institutionIds: institutionIds.value,
    tenantId: tenantId.value,
    breakdownByCohort: breakdownByCohort.value,
    breakdownByInstitution: breakdownByInstitution.value,
    curriculum: curriculum.value,
    time: timeFilter.value
  });
}

function resetReport() {
  reportData.value = [];
  curriculum.value = undefined;
  isReportActive.value = false;
  breakdownByCohort.value = false;
  breakdownByInstitution.value = false;
}

/**
 * Exports the report data to a CSV file
 */
function exportReportData(format: string) {
  exportData("subject-choice-completion", {
    institutionIds: institutionIds.value,
    tenantId: tenantId.value,
    breakdownByCohort: breakdownByCohort.value,
    breakdownByInstitution: breakdownByInstitution.value,
    curriculum: curriculum.value,
    time: timeFilter.value,
    format
  });
}
</script>
