<template>
  <v-card flat color="grey-lighten-4">
    <v-card-title>
      {{ $t('components.LocalesSelector.title') }}
    </v-card-title>
    <v-divider class="my-2"/>
    <v-card-text>
      <configuration-section-row>
        <template #name>
          {{ $t('components.LocalesSelector.application_locales_title') }}
        </template>
        <template #comment>
          {{ $t('components.LocalesSelector.application_locales_info') }}
        </template>

        <v-select
          variant="outlined"
          density="compact"
          v-model="availableLocales"
          :label="$t('components.LocalesSelector.application_locales')"
          :items="localeOptions"
          chips
          multiple
        />
        <v-select
          variant="outlined"
          density="compact"
          v-model="defaultLocale"
          :label="$t('components.LocalesSelector.default_locale')"
          :items="defaultLocaleOptions"
          :disabled="defaultLocaleOptions.length === 0"
        />
      </configuration-section-row>
      <configuration-section-row :show-divider="false">
        <template #name>
          {{ $t('components.LocalesSelector.assessment_locales_title') }}
        </template>
        <template #comment>
          {{ $t('components.LocalesSelector.assessment_locales_info') }}
        </template>

        <v-select
          variant="outlined"
          density="compact"
          v-model="assessmentLocales"
          :label="$t('components.LocalesSelector.assessment_locales')"
          :items="assessmentLocaleOptions"
          :disabled="assessmentLocaleOptions.length === 0"
          chips
          multiple
        />
        <v-select
          variant="outlined"
          density="compact"
          v-model="defaultAssessmentLocale"
          :label="$t('components.LocalesSelector.default_assessment_locale')"
          :items="defaultAssessmentLocaleOptions"
        />
      </configuration-section-row>
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn
        color="secondary"
        @click="reset"
        :disabled="!changed"
      >
        {{ $t('buttons.reset') }}
      </v-btn>
      <v-btn
        color="primary"
        @click="confirmVisible = true"
        :disabled="!valid || !changed"
      >
        {{ $t('buttons.apply') }}
      </v-btn>
    </v-card-actions>
  </v-card>
  <confirm-dialog
    :title="$t('common.confirm_title')"
    :text="$t('common.confirm_text')"
    v-model="confirmVisible"
    @confirm="save"
    @cancel="confirmVisible = false"
  />
</template>
<script setup lang="ts">
import {computed, ref, watch} from "vue";
import {assessmentLocaleOptions, localeOptions} from "@/lib/appConfiguration";
import {useGlobalStore} from "@/store";
import {isYapiError, YapiError} from "@YenzaCT/sdk";
import {useI18n} from "vue-i18n";
import {isEqual} from "lodash";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import ConfigurationSectionRow from "@/pages/AppConfigurationPage/ConfigurationSectionRow.vue";

const {t} = useI18n();
const store = useGlobalStore();

const availableLocales = ref<string[]>(store.appConfiguration.locales.available);
const defaultLocale = ref<string | null>(store.appConfiguration.locales.default);
const assessmentLocales = ref<string[]>(store.appConfiguration.locales.assessments);
const defaultAssessmentLocale = ref<string | null>(store.appConfiguration.locales.defaultAssessment);
const confirmVisible = ref(false);

const defaultLocaleOptions = computed(() => localeOptions.filter((locale) => availableLocales.value.includes(locale.value)));
const defaultAssessmentLocaleOptions = computed(() => assessmentLocaleOptions.filter((locale) => assessmentLocales.value.includes(locale.value)));
// Is the form valid?
const valid = computed(() => availableLocales.value.length && !!defaultLocale.value);

// Has the form changed?
const changed = computed(() => !isEqual({
  default: defaultLocale.value,
  available: availableLocales.value,
  assessment: assessmentLocales.value,
  defaultAssessmentLocale: defaultAssessmentLocale.value
}, store.appConfiguration.locales));

// Set default locale to null if it is removed from available
watch(availableLocales, () => {
  if (!availableLocales.value.includes(defaultLocale.value as string)) {
    defaultLocale.value = null;
  }
});

watch(assessmentLocales, () => {
  if (!assessmentLocales.value.includes(defaultAssessmentLocale.value as string)) {
    defaultAssessmentLocale.value = null;
  }
});

async function save() {
  try {
    await store.saveAppConfiguration("locales", {
      default: defaultLocale.value,
      available: availableLocales.value,
      defaultAssessment: defaultAssessmentLocale.value,
      assessments: assessmentLocales.value
    });
    store.showSnackbar(t("save_message"));
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    confirmVisible.value = false;
    reset();
  }
}

function reset() {
  availableLocales.value = store.appConfiguration.locales.available;
  defaultLocale.value = store.appConfiguration.locales.default;
  assessmentLocales.value = store.appConfiguration.locales.assessments;
  defaultAssessmentLocale.value = store.appConfiguration.locales.defaultAssessment;
}
</script>
