<template>
  <v-container class="fill-height" >
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-card class="pa-4" :loading="store.networkBusy">
          <v-card-title>
            {{ $t("coupon.create_coupon") }}
          </v-card-title>
          <v-form ref="form" v-model="valid" @submit.prevent="submit" data-pw="couponForm">
            <CouponFormFields
              :title="title"
              @update:title="title = $event"
              :titleRules="titleRules"
              :code="code"
              @update:code="code = $event"
              :codeRules="codeRules"
              :expiresAt="expiresAt"
              @update:expiresAt="expiresAt = $event"
              :expiresAtRules="expiresAtRules"
              :maxUses="maxUses"
              @update:maxUses="maxUses = $event"
              :maxUsesRules="maxUsesRules"
              :products="products"
              @update:products="products = $event"
              :productOptions="productOptions"
            />
            <CouponTypeAmount
              :type="type"
              @update:type="type = $event"
              :amount="amount"
              @update:amount="amount = $event"
              :amountRules="amountRules"
              :couponTypes="couponTypes"
            />
          </v-form>
          <CardActions
            @close="$emit('close')"
            @submit="submit"
            :valid="valid"
          />
        </v-card>
        <v-snackbar v-model="store.snackbarVisible">
          {{ store.snackbarMessage }}
        </v-snackbar>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import {ref} from "vue";
import yapi from "@/lib/yapi";
import {useI18n} from "vue-i18n";
import {ProductType, isYapiError, YapiError, Coupon} from "@YenzaCT/sdk";
import {VSelectOption} from "@/lib/vuetify";
import CouponTypeAmount from "@/pages/CouponTablePage/CreateCouponDialog/CouponTypeAmount.vue";
import CouponFormFields from "@/pages/CouponTablePage/CreateCouponDialog/CouponFormFields.vue";
import CardActions from "@/pages/CouponTablePage/CreateCouponDialog/CardActions.vue";
import {useGlobalStore} from "@/store";

const emit = defineEmits(["close"]);

const store = useGlobalStore();
const { t } = useI18n();
const title = ref("");
const code = ref("");
const type = ref("");
const amount = ref(0);
const expiresAt = ref<string>("");
const products = ref([]);
const maxUses = ref(0);
const valid = ref(false);

const titleRules = [(v: string | undefined) => !!v || t("coupon.title_required")];
const codeRules = [(v: string | undefined) => !!v || t("coupon.code_required")];
const amountRules = [(v: string | undefined) => !!v || t("coupon.amount_required")];
const maxUsesRules = [(v: number) => v >= 0 || t("coupon.max_uses_invalid")];
const expiresAtRules = [(v: string | undefined) => !!v || t("coupon.expires_at_required")];

const productOptions: VSelectOption[] = Object.values(ProductType).map((product) => ({
  title: t(`products.${product}`),
  value: product,
}));

const couponTypes: VSelectOption[] = [
  { title: "Full", value: "full"},
  { title: "Percent", value: "percent"},
  { title: "Fixed", value: "fixed"},
];

const submit = async () => {
  if (!valid.value || !expiresAt.value) {
    alert("invalid");
  }

  const couponData: Partial<Coupon> = {
    title: title.value,
    code: code.value,
    type: type.value,
    products: products.value,
    amount: amount.value,
    expiresAt: new Date(expiresAt.value),
    maxUses: maxUses.value,
    enabled: true,
    research: false,
    researchAssessments: [],
    usageCount: 0
  };
  store.networkBusy = true;
  try {
    await yapi.admin.coupon.create(couponData as Coupon);
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
      store.handleNetworkLoadingAndSnackbar(t("coupon.snackbarErrorMessage"));
    } else {
      throw e;
    }
  } finally {
    store.handleNetworkLoadingAndSnackbar(t("coupon.snackbarCompletedMessage"));
    emit("close");
  }
};
</script>
