<template>
  <v-card>
    <v-card-text>
      <v-list>
        <v-list-item>
          <v-list-item-title>
            {{  $t("components.ChangeResearchAssessment.toggle_research_heading") }}
          </v-list-item-title>
          <template #append>
            <v-switch
              v-model="researchAssessment"
              data-pw="changeResearchSwitch"/>
          </template>
        </v-list-item>
        <v-list-item v-if="researchAssessment">
          <div>
            <div>
              <h3>
                {{  $t("components.ChangeResearchAssessment.title") }}
              </h3>
            </div>
            <div>
              <h5>
                {{  $t("components.ChangeResearchAssessment.instruction") }}
              </h5>
            </div>
            <v-list-item
              v-for="(assessment, index) in researchAssessmentTypeOptions" :key="index">
              <v-checkbox :label="assessment.title" :value="assessment.value" data-pw="assessmentCheckbox"
                          @click="() => toggleResearchAssessment(assessment.value)"></v-checkbox>
            </v-list-item>
          </div>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn
        color="secondary"
        @click="$emit('close')"
      >
        {{ $t("buttons.cancel") }}
      </v-btn>
      <v-btn
        color="primary"
        @click="changeResearch"
      >
        {{ $t("buttons.confirm") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import {Ref, ref} from "vue";
import {AccessCode, AssessmentType, isYapiError, YapiError} from "@YenzaCT/sdk";
import yapi from "@/lib/yapi";
import {useGlobalStore} from "@/store";
import {useRoute} from "vue-router";
import {useI18n} from "vue-i18n";

const emit = defineEmits<{
  (e: "confirm"): void;
  (e: "close"): void;
}>();

const {t} = useI18n();
const store = useGlobalStore();
const route = useRoute();
const researchAssessment = ref(false);
const researchAssessmentTypeOptions = ref(Object.values(AssessmentType)
  .map((slug) => ({
    title: t(`components.ChangeResearchAssessment.assessments.${slug}`),
    value: slug
  })));

const selectedAssessmentsDefault: Record<string, unknown> = {};
Object.values(AssessmentType)
  .forEach((slug) => {
    selectedAssessmentsDefault[slug] = false;
  });
const researchSelected = ref(selectedAssessmentsDefault);
function toggleResearchAssessment(assessment: string) {
  researchSelected.value[assessment] = !researchSelected.value[assessment];
}
const accessCodeId: Ref<string> = ref("");
accessCodeId.value = route.params.id as string;
const changeResearch = async () => {
  try {
    const accessCode: AccessCode = {
      researchAssessments: Object.keys(researchSelected.value).filter((key) => researchSelected.value[key]),
    } as AccessCode;
    await yapi.admin.accessCode.edit(accessCodeId.value, accessCode);
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
    emit("close");
  }
};
</script>

<style scoped>

</style>
