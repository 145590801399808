<template>
  <v-expansion-panel data-pw="subjectChoicesPanel">
    <v-expansion-panel-title>
      {{  $t('components.SubjectChoicesPanel.title') }}
    </v-expansion-panel-title>
    <v-expansion-panel-text>
      <v-row>
        <v-col cols="6">
          <h3 class="font-weight-bold">{{  $t("components.SubjectChoicesPanel.curriculum") }}</h3>
          <p v-if="curriculum">{{  $t(`curriculums.${curriculum}.title`) }}</p>
          <p v-else>{{  $t('components.SubjectChoicesPanel.not_selected') }}</p>
        </v-col>
      </v-row>
      <v-row class="mb-3">
        <v-col cols="6">
          <h3 class="font-weight-bold mb-3">{{ $t('components.SubjectChoicesPanel.title') }}</h3>

          <p v-if="!selectedSubjectChoices">
            {{ $t('components.SubjectChoicesPanel.not_selected') }}
          </p>
          <v-list v-else v-for="(subjectChoice, subjectGroup) in selectedSubjectChoices" :key="subjectChoice">
            <v-list-item>
              <v-list-item-title>
                {{
                  $t(`curriculums.${curriculum}.subject_groups.${subjectGroup}`)
                }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{
                  subjectChoice
                    ? $t(`curriculums.${curriculum}.subjects.${subjectChoice}`)
                    : $t('components.SubjectChoicesPanel.not_selected')
                }}
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-expansion-panel-text>
  </v-expansion-panel>
</template>

<script lang="ts" setup>
import {computed} from "vue";
import {PropType} from "vue";

const props = defineProps({
  curriculum: {
    type: String as PropType<string | null | unknown>,
    default: "",
    required: true
  },
  subjectChoices: {
    type: Object as PropType<Record<string, unknown>>,
    required: true
  }
});

const selectedSubjectChoices = computed(() => {
  if (!props.subjectChoices || !props.curriculum) return null;

  return props.subjectChoices[props.curriculum];
});

</script>

<style scoped>
.readOnlyText {
  background-color: #f5f5f8;
  padding: 10px;
  margin: 2px;
  width: 100%
}
</style>
