<template>
  <report-filter-options
    :show-tenant-filter="true"
    :show-institution-filter="true"
    :show-cohort-filter="true"
    :show-cohort-breakdown="false"
    :is-report-active="isReportActive"
    :disable-run-report-button="false"
    @change-tenant="onTenantChange"
    @change-institution="onInstitutionSelectionChange"
    @change-cohort="onCohortChange"
    @click-run-report="runReport"
    @clickReset="resetReport"
  />

  <div v-if="loadingData">
    <v-row>
      <v-col>
        <v-skeleton-loader type="card"/>
      </v-col>
      <v-col>
        <v-skeleton-loader type="card"/>
      </v-col>
      <v-col>
        <v-skeleton-loader type="card"/>
      </v-col>
    </v-row>
  </div>
  <personality-trait-distribution-graphs v-if="isReportActive && reportDataValue && !loadingData" :report-data="reportDataValue"
                                         class="mt-6"/>

</template>
<script setup lang="ts">
import {useInsights} from "@/pages/ReportsDetailPage/reportsDetailPage";
import {computed} from "vue";
import ReportFilterOptions from "@/components/Reporting/ReportFilterOptions.vue";
import PersonalityTraitDistributionGraphs
  from "@/pages/ReportsDetailPage/components/PersonalityTraitDistributionGraphs.vue";
import {IPersonalityTraitsDistributionReportData} from "@/pages/ReportsDetailPage/reportTypes";

const reportDataValue = computed(() =>
  reportData.value && Object.keys(reportData.value).length > 0 ? reportData.value[0] : {}) as unknown as IPersonalityTraitsDistributionReportData;

const {
  timeFilter,
  tenantId,
  cohortId,
  institutionIds,
  isReportActive,
  reportData,
  loadingData,
  onTenantChange,
  onCohortChange,
  onInstitutionSelectionChange,
  getReportData,
} = useInsights();

/**
 * Fetches the Worker Type Distribution report data from the API.
 */
function runReport() {
  getReportData("personality-traits-distribution", {
    institutionIds: institutionIds.value,
    tenantId: tenantId.value,
    cohortId: cohortId.value,
    time: timeFilter.value
  });
}

function resetReport() {
  reportData.value = [];
  isReportActive.value = false;
  institutionIds.value = [];
  tenantId.value = undefined;
  cohortId.value = undefined;
}
</script>
