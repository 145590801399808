<template>

  <v-row v-if="!assessmentComplete">
    <v-col>
      <h2>{{ $t("assessments.assessment_not_done") }}</h2>
    </v-col>
  </v-row>

  <big5-report-skeleton-loader v-else-if="loading"/>

  <v-row v-else-if="loadReportContent">
    <v-col cols="12">
      <v-card color="#F5F5F8">
        <v-card-text>
          <wide-trait-score-card
            :title="personalityTraitsContent.factors.open_mindedness.title"
            :sub-title="personalityTraitsContent.factors.open_mindedness.subTitle"
            :result="Math.round(personalityTraitsContent.factors.open_mindedness.score * 100)"
            :colour="colors.open_mindedness"/>
          <v-divider/>
          <wide-trait-score-card
            :title="personalityTraitsContent.factors.conscientiousness.title"
            :sub-title="personalityTraitsContent.factors.conscientiousness.subTitle"
            :result="Math.round(personalityTraitsContent.factors.conscientiousness.score * 100)"
            :colour="colors.conscientiousness"/>
          <v-divider/>
          <wide-trait-score-card
            :title="personalityTraitsContent.factors.extraversion.title"
            :sub-title="personalityTraitsContent.factors.extraversion.subTitle"
            :result="Math.round(personalityTraitsContent.factors.extraversion.score * 100)"
            :colour="colors.extraversion"/>
          <v-divider/>
          <wide-trait-score-card
            :title="personalityTraitsContent.factors.agreeableness.title"
            :sub-title="personalityTraitsContent.factors.agreeableness.subTitle"
            :result="Math.round(personalityTraitsContent.factors.agreeableness.score * 100)"
            :colour="colors.agreeableness"/>
          <v-divider/>
          <wide-trait-score-card
            :title="personalityTraitsContent.factors.emotional_stability.title"
            :sub-title="personalityTraitsContent.factors.emotional_stability.subTitle"
            :result="Math.round(personalityTraitsContent.factors.emotional_stability.score * 100)"
            :colour="colors.neuroticism"/>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <personality-traits-overview :factors="personalityTraitsContent.factors"/>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import {computed, PropType, ref} from "vue";
import {Big5Result, isYapiError, PersonalityTraitsReportContent, YapiError} from "@YenzaCT/sdk";

import WideTraitScoreCard from "@/pages/UserDetailPage/AsessmentsDetail/WideTraitScoreCard.vue";
import PersonalityTraitsOverview
  from "@/pages/UserDetailPage/AsessmentsDetail/Big5Report/PersonalityTraitsOverview.vue";
import {AxiosResponse} from "axios";
import yapi from "@/lib/yapi";
import {useGlobalStore} from "@/store";
import Big5ReportSkeletonLoader from "@/pages/UserDetailPage/AsessmentsDetail/Big5Report/Big5ReportSkeletonLoader.vue";

const props = defineProps({
  score: {
    type: Object as PropType<Big5Result>,
    required: true
  }
});

const store = useGlobalStore();

const assessmentComplete = computed(() => {
  if (props.score) {
    return props.score.open_mindedness !== null && props.score.open_mindedness !== undefined &&
      props.score.conscientiousness !== null && props.score.conscientiousness !== undefined &&
      props.score.extraversion !== null && props.score.extraversion !== undefined &&
      props.score.agreeableness !== null && props.score.agreeableness !== undefined &&
      props.score.negative_emotionality !== null && props.score.negative_emotionality !== undefined;
  }
  return false;
});

const colors = {
  "open_mindedness": "green",
  "conscientiousness": "orange",
  "extraversion": "pink",
  "agreeableness": "blue",
  "neuroticism": "orange"
};

const loadReportContent = computed(() => {
  if (dataLoaded.value) {
    return personalityTraitsContent.value;
  }
  return null;
});
const dataLoaded = ref(false);
const loading = ref(false);
const personalityTraitsContent = ref();

async function fetchPersonalityTraitsReportContent() {
  loading.value = true;
  try {
    const res: AxiosResponse<PersonalityTraitsReportContent> = await yapi.assessmentReport.getPersonalityTraitsReportContent();
    personalityTraitsContent.value = res.data;
    dataLoaded.value = true;
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    loading.value = false;
  }
}

fetchPersonalityTraitsReportContent();
</script>
