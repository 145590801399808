<template>
  <v-card data-pw="changePhoneDialogCard">
    <v-card-title>
      {{ $t('components.DownloadSubjectChoiceDialog.title') }}
    </v-card-title>
    <v-card-text>
      <span v-if="allowDownload">
        {{ $t("components.DownloadSubjectChoiceDialog.download_instruction") }}
      </span>
      <span v-else>
        {{ $t("components.DownloadSubjectChoiceDialog.no_curriculum_selected") }}
      </span>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn
        data-pw="cancelDownloadSubjectChoiceReportButton"
        color="secondary"
        @click="$emit('close')"
      >
        {{ $t("buttons.cancel") }}
      </v-btn>
      <v-btn
        color="primary"
        @click="downloadReport"
        :disabled="!allowDownload"
        data-pw="confirmDownloadSubjectChoiceReportButton"
      >
        {{ $t("buttons.confirm") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import {computed, PropType, ref, Ref} from "vue";
import {isYapiError, DashUser, YapiError} from "@YenzaCT/sdk";
import {useGlobalStore} from "@/store";
import yapi from "@/lib/yapi";
import download from "@/lib/documentDownload";

const store = useGlobalStore();

const props = defineProps({
  user: {
    type: Object as PropType<DashUser>,
    required: true
  }
});

const user: Ref<DashUser> = ref({...props.user} as DashUser);

const allowDownload = computed(() =>
  user.value.profile.schoolCurriculum &&
  user.value.profile.schoolCurriculumChoices[user.value.profile.schoolCurriculum]);

const emit = defineEmits<{
  (e: "confirm"): void;
  (e: "close"): void;
}
>();

async function downloadReport() {
  try {
    const res = await yapi.admin.user.getDocument(
      user.value?._id as string,
      "subject-choice-report",
    );
    download(res.data, "Subject Choice Report");
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
    emit("close");
  }
}
</script>
