<template>
  <v-card>
    <v-card-title>{{ $t("components.CreateCohortDialog.title") }}</v-card-title>
    <v-card-text>
      <v-form @submit.prevent @submit="createCohort">
        <v-text-field
          :label="$t('common.title')"
          v-model="title"
          variant="outlined"
          :rules="titleRules"/>
      </v-form>
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn color="secondary" @click="$emit('close')">
        {{ $t("buttons.cancel") }}
      </v-btn>
      <v-btn @click="createCohort" color="primary" type="submit">
        {{ $t("buttons.create") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import {ref} from "vue";
import yapi from "@/lib/yapi";
import {useRouter} from "vue-router";
import {useI18n} from "vue-i18n";

const { t } = useI18n();
const router = useRouter();

const props = defineProps<{ id: string }>();
const title = ref("");
const titleRules = [
  (v: string) => !!v || t("common.title_required")
];

async function createCohort() {
  const cohort = await yapi.admin.cohort.create(title.value, props.id);

  await router.push({
    name: "cohortDetail",
    params: {id: cohort.data._id}
  });
}
</script>
