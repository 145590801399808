<template>
  <v-row v-if="!assessmentComplete">
    <v-col>
      <h2>{{ $t("translations.assessments.assessment_not_done") }}</h2>
    </v-col>
  </v-row>

  <learning-styles-report-skeleton-loader v-else-if="loading"/>

  <v-row v-else-if="loadReportContent">
    <!--  types and results-->
    <v-col cols="12">
      <v-row class="ma-2">
        <v-col cols="12">
          <LearningStyleTraitScoreCard
            :title="learningStylesReportContent.labels.data_driven_vs_conceptual"
            :left-label="learningStylesReportContent.labels.data_driven"
            :right-label="learningStylesReportContent.labels.conceptual"
            :score="learningStylesReportContent.narratives.DC.score"
            :colour="DC_colour"
          />
        </v-col>
        <v-col cols="12">
          <LearningStyleTraitScoreCard
            :title="learningStylesReportContent.labels.optic_vs_written"
            :left-label="learningStylesReportContent.labels.optic"
            :right-label="learningStylesReportContent.labels.written"
            :score="learningStylesReportContent.narratives.OW.score"
            :colour="OW_colour"
          />
        </v-col>
      </v-row>
      <v-row class="ma-2">
        <v-col cols="12">
          <LearningStyleTraitScoreCard
            :title="learningStylesReportContent.labels.structured_vs_fluid"
            :left-label="learningStylesReportContent.labels.structured"
            :right-label="learningStylesReportContent.labels.fluid"
            :score="learningStylesReportContent.narratives.SF.score"
            :colour="SF_colour"
          />
        </v-col>
        <v-col cols="12">
          <LearningStyleTraitScoreCard
            :title="learningStylesReportContent.labels.doing_vs_thinking"
            :left-label="learningStylesReportContent.labels.doing"
            :right-label="learningStylesReportContent.labels.thinking"
            :score="learningStylesReportContent.narratives.DT.score"
            :colour="DT_colour"
          />
        </v-col>
      </v-row>
    </v-col>

    <!-- Overview -->
    <v-col cols="12">
      <learning-styles-overview :reportContent="learningStylesReportContent"/>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import {PropType, ref, computed} from "vue";
import {
  isYapiError,
  LearningStylesReportContent,
  LearningStylesResult,
  YapiError
} from "@YenzaCT/sdk";
import LearningStylesOverview
  from "@/pages/UserDetailPage/AsessmentsDetail/LearningStyleReport/LearningStylesOverview.vue";
import LearningStyleTraitScoreCard
  from "@/pages/UserDetailPage/AsessmentsDetail/LearningStyleReport/LearningStyleTraitScoreCard.vue";
import {AxiosResponse} from "axios";
import yapi from "@/lib/yapi";
import {useGlobalStore} from "@/store";
import LearningStylesReportSkeletonLoader
  from "@/pages/UserDetailPage/AsessmentsDetail/LearningStyleReport/LearningStylesReportSkeletonLoader.vue";

const props = defineProps({
  score: {
    type: Object as PropType<LearningStylesResult>,
    required: true
  },
});

const store = useGlobalStore();
const DC_colour = "orange";
const OW_colour = "blue";
const DT_colour = "green";
const SF_colour = "purple";

const loadReportContent = computed(() => {
  if (dataLoaded.value) {
    return learningStylesReportContent.value;
  }
  return null;
});
const dataLoaded = ref(false);
const loading = ref(false);
const learningStylesReportContent = ref();

const assessmentComplete = computed(() => {
  if (props.score) {
    return props.score.scores.DC !== null && props.score.scores.DC !== undefined &&
      props.score.scores.DT !== null && props.score.scores.DT !== undefined &&
      props.score.scores.OW !== null && props.score.scores.OW !== undefined &&
      props.score.scores.SF !== null && props.score.scores.SF !== undefined;
  }
  return false;
});

async function fetchLearningStylesReportContent() {
  loading.value = true;
  try {
    const res: AxiosResponse<LearningStylesReportContent> = await yapi.assessmentReport.getLearningStylesReportContent();
    learningStylesReportContent.value = res.data;
    dataLoaded.value = true;
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    loading.value = false;
  }
}

fetchLearningStylesReportContent();

</script>
