<template>
  <v-card>
    <v-card-text>
      <p class="text-caption">{{ $t("components.ResetAssessmentDialog.title") }}</p>

      <v-radio-group v-model="selectedItem">
        <v-radio
          v-for="(value, key) in selectedItems"
          :key="key"
          :label="$t(`assessments.${key}.title`)"
          :value="convertToSlugFormat(key)"
        ></v-radio>
      </v-radio-group>

      <v-divider class="my-4" />

      <v-row>
        <v-col cols="12">
          <span class="text-red font-weight-bold">{{ $t("components.ResetAssessmentDialog.warning_label") }}</span>
          <span>{{ $t("components.ResetAssessmentDialog.action_label") }}</span>
          <v-text-field
            v-model="confirmText"
            density="compact"
            variant="outlined"
            :label="$t('common.type_confirm_message')"
            class="mt-2"
          />
        </v-col>
      </v-row>

      <v-divider class="mb-4" />
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="submitForm" :disabled="!canSubmit">{{ $t("buttons.confirm") }}</v-btn>
      <v-spacer/>
      <v-btn color="secondary" @click="closeAndReload">{{ $t("buttons.cancel") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { reactive, computed, ref, PropType, Ref } from "vue";
import yapi from "@/lib/yapi";
import { useGlobalStore } from "@/store";
import { isYapiError, DashUser, YapiError } from "@YenzaCT/sdk";
const store = useGlobalStore();
const props = defineProps({
  user: {
    type: Object as PropType<DashUser>,
    required: true
  }
});

const user: Ref<DashUser> = ref({
  ...props.user,
});

const userId: Ref<string> = ref("");
userId.value = user?.value._id as string;

const error = ref<string | undefined>("");

const emits = defineEmits<{
  (e: "close"): void;
}
>();

const selectedItems = reactive({
  holland_code: false,
  big_5: false,
  learning_styles: false,
  managing_emotion: false,
  perceiving_emotion: false,
  understanding_emotion: false,
  maze: false,
  rock_paper_scissors: false,
});

const confirmText = ref("");
const selectedItem = ref("");

const canSubmit = computed(() => confirmText.value === "CONFIRM" && selectedItem.value);

function convertToSlugFormat(key: string) {
  return key.replace(/([A-Z])/g, "_$1").toLowerCase();
}

async function submitForm() {
  try {
    store.networkBusy = true;
    await yapi.admin.user.resetAssessment(userId.value, selectedItem.value);
    emits("close");
    store.showSnackbar("The requested assessment has been reset.");
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
      error.value = yError.response?.data.message;
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
  }
}

function closeAndReload() {
  emits("close");
}
</script>

<style scoped>

</style>
