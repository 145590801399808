<template>
  <v-card>
    <v-card-text>
      <v-list>
        <v-list-item>
          <v-list-item-title>{{ $t("components.DeleteCohort.title") }}</v-list-item-title>
          <v-card-text>
            <v-alert density="compact" v-if="error" type="error" class="mb-4"> {{ error }}</v-alert>
            {{ message }}
          </v-card-text>
          <v-text-field
            v-model="verifyString"
            variant="outlined"
            :label="`Please type in '${confirmString}'`"
          >
          </v-text-field>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn
        color="secondary"
        @click="$emit('close')"
      >
        {{ $t("buttons.close") }}
      </v-btn>
      <v-btn
        color="primary"
        @click="deleteCohort"
        v-bind:disabled="verifyString !== confirmString"
      >
        {{ $t("buttons.confirm") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { ref} from "vue";
import yapi from "@/lib/yapi";
import {isYapiError, YapiError} from "@YenzaCT/sdk";
import {useGlobalStore} from "@/store";
import {useRoute, useRouter} from "vue-router";
import {useI18n} from "vue-i18n";

const {t} = useI18n();
const router = useRouter();

defineProps({
  confirmString: {
    type: String,
    required: true
  },
  message: {
    type: String,
    required: true
  }
});

defineEmits<{
  (e: "confirm"): void;
  (e: "close"): void;
}>();

const store = useGlobalStore();
const route = useRoute();

const verifyString = ref("");
const institutionId = route.params.institutionId as string;
const cohortId = route.params.id as string;
const error = ref<string | undefined>("");

const deleteCohort = async () => {
  try {
    await yapi.admin.cohort.remove(cohortId);
    await router.push({name: "institutionDetail", params: {id: institutionId}, query: {tab: "cohorts"}});
    store.showSnackbar(t("components.DeleteCohort.delete_message"));
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
      error.value = yError.response?.data.message;
    } else {
      throw e;
    }
  }
};
</script>

