<template>
  <detail-row
    v-if="accessCodes && accessCodes.length > 0"
    icon="far fa-user"
    :label="$t('accessCode.code')"
    data-pw="userAccessCode"
    :value="accessCodes[0].code"
  />
</template>

<script setup lang="ts">
import {ref, onMounted, PropType} from "vue";
import DetailRow from "@/components/DetailRow.vue";
import {AccessCode, isYapiError, YapiError} from "@YenzaCT/sdk";
import {useGlobalStore} from "@/store";
import yapi from "@/lib/yapi";
const store = useGlobalStore();
const accessCodes = ref<AccessCode[]>([]);

const props = defineProps({
  userId: {
    type: String as PropType<string>,
    required: true
  }
});

onMounted(async () => {
  try {
    store.networkBusy = true;
    accessCodes.value = (await yapi.admin.user.getAccessCodes(props.userId)).data;
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
  }
});
</script>

<style scoped>

</style>
