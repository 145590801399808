<template>
  <v-card>
    <v-card-text>
      <v-list>
        <v-list-item>
          <v-list-item-title>{{$t("accessCode.account_type")}}</v-list-item-title>
          <choose-voucher-account-type v-model="accountType" data-pw="chooseNewAccountType"/>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn
        data-pw="cancelButton"
        color="secondary"
        @click="$emit('close')"
      >
        {{ $t("buttons.cancel") }}
      </v-btn>
      <v-btn
        color="primary"
        data-pw="changeAccountButton"
        @click="changeAccount"
      >
        {{ $t("buttons.confirm") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import {Ref, ref} from "vue";
import {AccessCode, AccountType, isYapiError, YapiError} from "@YenzaCT/sdk";
import ChooseVoucherAccountType from "@/components/Voucher/ChooseVoucherAccountType.vue";
import yapi from "@/lib/yapi";
import {useGlobalStore} from "@/store";
import {useRoute} from "vue-router";
import {useI18n} from "vue-i18n";

const emit = defineEmits<{
  (e: "confirm"): void;
  (e: "close"): void;
}>();

const store = useGlobalStore();
const route = useRoute();
const {t} = useI18n();
const voucherId: Ref<string> = ref("");
voucherId.value = route.params.id as string;
const accountType = ref<AccountType | string>(t("accountTypes.starter"));

const changeAccount = async () => {
  try {
    const accessCode = {
      accountType: accountType.value
    } as AccessCode;
    await yapi.admin.accessCode.edit(voucherId.value, accessCode);
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
    emit("close");
  }
};
</script>
<style scoped>

</style>
