<template>
  <v-list density="compact">
    <h3>{{ $t("common.assessments") }}</h3>
    <progress-detail :is-complete="!!assessments?.holland_code" data-pw="hollandCodeListItem">
      {{ $t("assessments.holland_code.title") }}
    </progress-detail>
    <progress-detail v-if="featureAllowed(Feature.PersonalityTraitsReport)" :is-complete="!!assessments.big_5"
                     data-pw="big5ListItem">
      {{ $t("assessments.big_5.title") }}
    </progress-detail>
    <progress-detail v-if="featureAllowed(Feature.EntrepreneurReport)"
                     :is-complete="!!assessments.holland_code" data-pw="entrepreneurialIntentListItem">
      {{ $t("assessments.entrepreneurial_intent.title") }}
    </progress-detail>
    <progress-detail v-if="featureAllowed(Feature.LearningStylesReport)" :is-complete="!!assessments.learning_styles"
                     data-pw="learningStylesListItem">
      {{ $t("assessments.learning_styles.title") }}
    </progress-detail>
    <progress-detail v-if="featureAllowed(Feature.EmotionalIntelligenceReport)"
                     :is-complete="!!assessments.managing_emotion" data-pw="managingEmotionListItem">
      {{ $t("assessments.managing_emotion.title") }}
    </progress-detail>
    <progress-detail v-if="featureAllowed(Feature.EmotionalIntelligenceReport)"
                     :is-complete="!!assessments.perceiving_emotion" data-pw="perceivingEmotionListItem">
      {{ $t("assessments.perceiving_emotion.title") }}
    </progress-detail>
    <progress-detail v-if="featureAllowed(Feature.EmotionalIntelligenceReport)"
                     :is-complete="!!assessments.understanding_emotion" data-pw="understandingEmotionListItem">
      {{ $t("assessments.understanding_emotion.title") }}
    </progress-detail>
    <progress-detail v-if="featureAllowed(Feature.CognitiveAbilityReport)"
                     :is-complete="!!assessments.rock_paper_scissors" data-pw="rockPaperScissorsListItem">
      {{ $t("assessments.rock_paper_scissors.title") }}
    </progress-detail>
    <progress-detail v-if="featureAllowed(Feature.CognitiveAbilityReport)" :is-complete="!!assessments.maze"
                     data-pw="mazeListItem">
      {{ $t("assessments.maze.title") }}
    </progress-detail>
    <div v-if="research.length">
      <progress-detail :is-complete="!!assessments.ipip" data-pw="ipipListItem">
        {{ $t("assessments.ipip.title") }}
      </progress-detail>
      <progress-detail :is-complete="!!assessments.interests" data-pw="interestsListItem">
        {{ $t("assessments.interests.title") }}
      </progress-detail>
    </div>
  </v-list>
</template>

<script setup lang="ts">
import {PropType} from "vue";
import {AllowedFeature, Assessments, Feature, AccountType, CountryCode} from "@YenzaCT/sdk";
import ProgressDetail from "@/pages/UserDetailPage/ProgressCard/ProgressDetail.vue";

const props = defineProps({
  assessments: {
    type: Object as PropType<Assessments>,
    required: true
  },
  accountType: {
    type: String as PropType<AccountType>,
    required: true
  },
  country: {
    type: String as PropType<CountryCode>,
    required: true
  },
  research: {
    type: Array as PropType<string[]>,
    required: true
  }
});

function featureAllowed(feature: Feature) {
  return AllowedFeature[props.country][props.accountType].includes(feature);
}
</script>
