<template>
  <v-card flat color="grey-lighten-4">
    <v-card-title>
      {{ $t("components.InitialProfileSelector.title") }}
    </v-card-title>
    <v-card-subtitle>
      {{ $t("components.InitialProfileSelector.subtitle")}}
    </v-card-subtitle>
    <v-divider class="my-2" />
    <v-card-text>
      <configuration-section-row v-for="(c, index) in initialProfile" :key="index">
        <template #name>
          {{ $t(`components.InitialProfileSelector.${index}`) }}
        </template>
        <labeled-switch
          v-model="initialProfile[index]"
        />
      </configuration-section-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="secondary"
        @click="reset"
        :disabled="!changed"
        variant="text"
      >
        {{ $t('buttons.reset') }}
      </v-btn>
      <v-btn
        color="primary"
        @click="confirmVisible = true"
        :disabled="!changed"
        variant="text"
      >
        {{ $t('buttons.apply') }}
      </v-btn>
    </v-card-actions>
  </v-card>

  <confirm-dialog
    :title="$t('common.confirm_title')"
    :text="$t('common.confirm_text')"
    v-model="confirmVisible"
    @confirm="save"
    @cancel="confirmVisible = false"
  />
</template>
<script setup lang="ts">
import {useGlobalStore} from "@/store";
import {ref, computed} from "vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import {isEqual} from "lodash";
import LabeledSwitch from "@/components/LabeledSwitch.vue";
import {isYapiError, YapiError} from "@YenzaCT/sdk";
import ConfigurationSectionRow from "@/pages/AppConfigurationPage/ConfigurationSectionRow.vue";

const store = useGlobalStore();

const confirmVisible = ref(false);
const initialProfile = ref({
  ...store.appConfiguration.initialProfile
});

const changed = computed(() => !isEqual(store.appConfiguration.initialProfile, initialProfile.value));

function reset() {
  initialProfile.value = {...store.appConfiguration.initialProfile};
}

async function save() {
  try {
    await store.saveAppConfiguration("initialProfile", initialProfile.value);
    store.showSnackbar("Initial Profile updated");
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    confirmVisible.value = false;
    reset();
  }
}
</script>

<style scoped>

</style>
