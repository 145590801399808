<template>
  <h3 class="mt-4">{{ $t('common.results') }}</h3>
  <v-list density="compact">
    <progress-detail :isComplete="profile.favourites.careers.length > 0" data-pw="careersPanel">
      {{ $t('components.ChoicesCompletion.favourited_careers') }}
    </progress-detail>
    <progress-detail :isComplete="subjectsSelected" data-pw="subjectChoicesPanel">
      {{ $t('components.ChoicesCompletion.subject_choices') }}
    </progress-detail>
  </v-list>
</template>

<script setup lang="ts">
import ProgressDetail from "@/pages/UserDetailPage/ProgressCard/ProgressDetail.vue";
import {computed, onMounted, PropType, ref} from "vue";
import {Curricula, isYapiError, Profile, YapiError} from "@YenzaCT/sdk";
import yapi from "@/lib/yapi";
import {useGlobalStore} from "@/store";

const store = useGlobalStore();
const props = defineProps({
  profile: {
    type: Object as PropType<Profile>,
    required: true
  }
});
const curricula = ref<Curricula>({});

onMounted(async () => {
  await fetchCurricula();
});

/**
 * Fetches the curricula from the API.
 */
async function fetchCurricula() {
  try {
    store.networkBusy = true;
    curricula.value = (await yapi.admin.curriculum.list()).data;
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
  }
}

const subjectsSelected = computed(() => {
  const curriculumKey = props.profile.schoolCurriculum as string;

  if (!curriculumKey) return false;

  const curriculumChoices = props.profile.schoolCurriculumChoices[curriculumKey] as Record<string, string>;
  const curriculumDetails = curricula.value[curriculumKey];

  if (!curriculumChoices || !curriculumDetails) return false;

  return Object.entries(curriculumDetails.subjectGroups)
    .every(([groupKey, groupDetails]) => !groupDetails.mandatory || curriculumChoices[groupKey]);
});

</script>
