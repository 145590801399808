<template>
  <v-card>
    <v-card-title>
      {{$t("components.ManagersTable.title")}}
    </v-card-title>
    <v-card-text>
      <div v-if="managers.length === 0">
        {{$t("components.ManagersTable.no_manager_message")}}
      </div>
      <v-table v-else density="compact">
        <thead>
          <tr>
            <th>{{$t("components.ManagersTable.email")}}</th>
            <th>{{$t("components.ManagersTable.first_name")}}</th>
            <th>{{$t("components.ManagersTable.last_name")}}</th>
            <th>{{$t("components.ManagersTable.actions")}}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="selectable"
            v-for="manager in managers"
            :key="manager._id"
          >
            <td @click="openUserDetail(manager._id!)">
              {{ manager.email }}
            </td>
            <td @click="openUserDetail(manager._id!)">
              {{ manager.profile.firstName }}
            </td>
            <td @click="openUserDetail(manager._id!)">
              {{ manager.profile.lastName }}
            </td>
            <td>
              <v-btn
                color="primary"
                variant="text"
                @click="deleteManager(manager)"
              >
                {{$t("components.ManagersTable.revoke_permissions")}}
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-table>
    </v-card-text>
  </v-card>
  <v-dialog
    v-model="showDeleteManagerDialog"
    max-width="500px">
    <delete-manager-dialog
      v-if="showDeleteManagerDialog"
      :entity-type="entityType"
      :manager="selectedUser!"
      :confirmString="$t('components.ManagersTable.delete') + `${selectedUser!.email}`"
      :message="$t('components.ManagersTable.revoke_warning_message')"
      @close="showDeleteManagerDialog = false"
      @revoked="handleRevoked"
    />
  </v-dialog>
</template>

<script setup lang="ts">
import {Entity, User} from "@YenzaCT/sdk";
import {PropType, ref} from "vue";
import router from "@/router";
import deleteManagerDialog from "@/components/RevokeManagerPermissionsDialog.vue";

defineProps({
  managers: {
    type: Array as PropType<User[]>,
    required: true,
  },
  entityType: {
    type: String as PropType<Entity>,
    required: true,
  },
});

const emit = defineEmits<{
  (e: "revoked"): void;
}>();

const selectedUser = ref<User>();

const showDeleteManagerDialog = ref(false);

function handleRevoked() {
  showDeleteManagerDialog.value = false;
  emit("revoked");
}

function deleteManager(manager: User) {
  showDeleteManagerDialog.value = true;
  selectedUser.value = manager;
}

async function openUserDetail(id: string) {
  await router.push({name: "userDetail", params: {id}});
}
</script>
