<template>
  <v-row>
    <v-col cols="12">

      <v-form
        ref="forgotPasswordForm"
        v-model="isFormValid"
        class="text-center"
      >
        <EmailPhoneRadioInput
          v-model="inputType"
          class="mb-4"
        />
        <EmailTextField
          v-if="inputType === 'email'"
          v-model="contact"
          @validate="handleEmailValidate"
        />
        <InternationalPhoneNumberInput
          v-else-if="inputType === 'phone'"
          v-model="contact"
          class="mb-6 phone-input"
          @validate="handlePhoneValidate"
        />

        <v-alert v-if="accountNotFound" type="error" density="compact" class="mb-2">
          {{ $t("components.ForgotPasswordForm.account_not_found") + contact }}
        </v-alert>

        <v-alert v-if="tooManyRequestsError" type="warning" density="compact" class="mb-2">
          {{ $t("components.ForgotPasswordForm.too_recent") }}
        </v-alert>

        <p class="font-weight-bold pb-3">
          {{ $t("components.ForgotPasswordForm.you_will_receive_otp") }}
        </p>

        <v-btn
          :loading="loading"
          variant="elevated"
          :block="true"
          color="primary"
          class="ma-auto"
          :disabled="!canSubmit"
          @click="submitForgotPasswordForm"
          data-pw="resetLinkButton"
        >
          <v-icon class="mr-2">fa fa-paper-plane</v-icon>
          {{ $t("components.ForgotPasswordForm.send_OTP") }}
        </v-btn>
      </v-form>

      <v-divider class="mt-4" />

      <v-btn
        :block="true"
        variant="outlined"
        :to="{ name: 'login'}"
        data-pw="backToLoginButton"
        color="primary"
        class="mt-4 bg-grey-lighten-3">
        <v-icon class="mr-2">fa fa-arrow-left</v-icon>
        {{ $t("buttons.cancel")  }}
      </v-btn>

    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import {computed, ref} from "vue";
import yapi from "@/lib/yapi";
import {useGlobalStore} from "@/store";
import {isYapiError, YapiError} from "@YenzaCT/sdk";
import EmailTextField from "@/components/FormFields/EmailTextField.vue";
import InternationalPhoneNumberInput from "@/components/FormFields/InternationalPhoneNumberInput.vue";
import EmailPhoneRadioInput from "@/components/FormFields/EmailPhoneRadioInput.vue";

const store = useGlobalStore();
const contact = ref("");
const isFormValid = ref(false);
const isPhoneValid = ref(false);
const isEmailValid = ref(false);
const loading = ref(false);
const emit = defineEmits(["submit"]);
const inputType = ref("email");
const accountNotFound = ref(false);
const tooManyRequestsError = ref(false);

const canSubmit = computed(() => (isFormValid.value && inputType.value !== "phone") || (isPhoneValid.value && inputType.value === "phone"));

function handleEmailValidate(isValid: boolean) {
  isEmailValid.value = isValid;
}

function handlePhoneValidate(isValid: boolean) {
  isPhoneValid.value = isValid;
}

const submitForgotPasswordForm = async () => {
  loading.value = true;
  try {
    const sendSms = inputType.value === "phone";
    const res = await yapi.auth.forgotPassword(contact.value, sendSms);

    if (res.status === 200) {
      emit("submit", {contact: contact.value, type: inputType.value});
    }
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
      if (yError.response && yError.response.status === 404) accountNotFound.value = true;

      if (yError.response && yError.response.status === 409) tooManyRequestsError.value = true;
    } else {
      throw e;
    }
  } finally {
    loading.value = false;
  }
};
</script>
