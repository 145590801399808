<template>
  <report-filter-options
    :show-tenant-filter="true"
    :show-institution-filter="true"
    :show-cohort-filter="true"
    :show-cohort-breakdown="false"
    :allow-export="true"
    :is-report-active="isReportActive"
    :disable-run-report-button="!curriculum"
    @change-tenant="onTenantChange"
    @change-institution="onInstitutionSelectionChange"
    @change-cohort="onCohortChange"
    @click-run-report="runReport"
    @click-export-report="exportReportData"
    @click-reset="resetReport"
  >
    <template #filters>
      <v-col>
        <CurriculumSelectDropdown v-model="curriculum" @change="onCurriculumChange"/>
      </v-col>
    </template>
    <template #filterList>
      <v-list-item>
        <span class="font-weight-bold">{{ $t('common.curriculum') }}: </span> {{
          $t(`curriculums.${curriculum}.title`)
        }}
      </v-list-item>
    </template>
  </report-filter-options>

  <user-subject-choices-data-table v-if="curriculum" :loading-data="loadingData" :curriculum="curriculum"
                                   :report-data="reportData"/>
</template>

<script setup lang="ts">
import {useInsights} from "@/pages/ReportsDetailPage/reportsDetailPage";
import ReportFilterOptions from "@/components/Reporting/ReportFilterOptions.vue";
import CurriculumSelectDropdown from "@/components/FormFields/CurriculumSelectDropdown.vue";
import UserSubjectChoicesDataTable from "@/pages/ReportsDetailPage/components/UserSubjectChoicesDataTable.vue";

const {
  timeFilter,
  tenantId,
  cohortId,
  curriculum,
  institutionIds,
  isReportActive,
  breakdownByCohort,
  reportData,
  loadingData,
  onTenantChange,
  onCohortChange,
  onCurriculumChange,
  onInstitutionSelectionChange,
  getReportData,
  exportData,
} = useInsights();

/**
 * Fetches the User Subject Choices report data
 */
function runReport() {
  getReportData("users-subject-choices", {
    institutionIds: institutionIds.value,
    cohortId: cohortId.value,
    tenantId: tenantId.value,
    curriculum: curriculum.value,
    breakdownByCohort: breakdownByCohort.value,
    time: timeFilter.value
  });
}

/**
 * Exports the User Subject Choices report data to the server
 */
function exportReportData(format: string) {
  exportData("users-subject-choices", {
    institutionIds: institutionIds.value,
    tenantId: tenantId.value,
    cohortId: cohortId.value,
    curriculum: curriculum.value,
    breakdownByCohort: breakdownByCohort.value,
    time: timeFilter.value,
    format
  });
}

function resetReport() {
  reportData.value = [];
  curriculum.value = undefined;
}

</script>
