<template>
  <v-card>
    <v-card-title>
      {{ $t('components.ChangeUserRole.title') }}
    </v-card-title>
    <v-card-subtitle>{{ $t("components.ChangeUserRole.subtitle") }}</v-card-subtitle>
    <v-card-text>
      <v-select
        v-model="role"
        :items="roles"
        item-title="title"
        item-value="value"
        variant="outlined" density="compact"
        :label="$t(`roles.${currentRole}`)"
        no-data-text="User Roles"
        @update:model-value="handleRoleSelect"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn
        @click="$emit('close')"
      >
        {{ $t("buttons.cancel") }}
      </v-btn>
      <v-btn
        color="primary"
        @click="changeRole"
      >
        {{ $t("buttons.confirm") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import {PropType, ref, Ref} from "vue";
import {isYapiError, DashUser, Role, YapiError, User} from "@YenzaCT/sdk";

import {useGlobalStore} from "@/store";
import yapi from "@/lib/yapi";
import {useI18n} from "vue-i18n";

const { t } = useI18n();
const store = useGlobalStore();

const props = defineProps({
  user: {
    type: Object as PropType<DashUser>,
    required: true
  }
});
const user: Ref<DashUser> = ref({...props.user} as DashUser);
const role = ref<Role>();
const currentRole = ref<string>("");
const selectedRole = ref<Role>();

const roles = ref(Object.values(Role)
  .map((slug) => ({
    title: t(`roles.${slug}`),
    value: slug
  })));

currentRole.value = user.value.app.role;
role.value = user.value.app.role;

function handleRoleSelect(role: Role) {
  selectedRole.value = role;
}

const emit = defineEmits<{
  (e: "confirm"): void;
  (e: "close"): void;
}
>();

const changeRole = async () => {
  try {
    if (selectedRole.value && user.value._id) {
      user.value.app.role = selectedRole.value;
      await yapi.admin.user.edit(user.value._id, user.value as User);
    }
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
    emit("close");
  }
};
</script>
