<script setup lang="ts">
import {computed, ref} from "vue";
import ConfigurationSectionRow from "@/pages/AppConfigurationPage/ConfigurationSectionRow.vue";
import LabeledSwitch from "@/components/LabeledSwitch.vue";
import {useGlobalStore} from "@/store";
import {isYapiError, YapiError} from "@YenzaCT/sdk";
import {isEqual} from "lodash";
import ConfirmDialog from "@/components/ConfirmDialog.vue";

const store = useGlobalStore();

const policies = ref({...store.appConfiguration.policies});

const changed = computed(
  () => !isEqual(store.appConfiguration.policies, policies.value)
);
const confirmVisible = ref(false);

async function save() {
  try {
    await store.saveAppConfiguration("policies", policies.value);
    store.showSnackbar("Saved policy configuration");
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    reset();
  }
}

function reset() {
  policies.value = {...store.appConfiguration.policies};
}

</script>

<template>
  <v-card flat color="grey-lighten-4">
    <v-card-title>
      Policy Documents
    </v-card-title>
    <v-card-subtitle>
      The following allows custom terms and policies to be shown.
    </v-card-subtitle>
    <v-divider />
    <v-card-text>
      <configuration-section-row>
        <template #name>
          Show privacy policy
        </template>
        <template #comment>
          Will enable showing the privacy policy on the app.
        </template>
        <labeled-switch
          v-model="policies.privacy"
        />
      </configuration-section-row>

      <configuration-section-row v-if="policies.privacy">
        <template #name>
          Privacy Policy Sanity ID
        </template>
        <template #comment>
          The Sanity CMS ID for the Privacy Policy content.
          If left blank, default will be used.
        </template>
        <v-text-field
          v-model="policies.privacyPolicyDocumentId"
          color="primary"
          label="ID"
        />
      </configuration-section-row>
      <configuration-section-row>
        <template #name>
          Show Terms and Conditions
        </template>
        <template #comment>
          Will enable showing the terms on conditions on the app.
        </template>
        <labeled-switch
          v-model="policies.termsAndConditions"
        />
      </configuration-section-row>
      <configuration-section-row v-if="policies.termsAndConditions">
        <template #name>
          Terms and Conditions Sanity ID
        </template>
        <template #comment>
          The Sanity CMS ID for the Terms and Conditions content.
          If left blank, default will be used.
        </template>
        <v-text-field
          v-model="policies.termsAndConditionsDocumentId"
          color="primary"
          label="ID"
        />
      </configuration-section-row>
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn
        @click="reset"
        color="secondary"
        :disabled="!changed"
      >
        {{ $t("buttons.reset") }}
      </v-btn>
      <v-btn
        @click="confirmVisible = true"
        color="primary"
        :disabled="!changed"
      >
        {{ $t("buttons.apply") }}
      </v-btn>
    </v-card-actions>
  </v-card>

  <confirm-dialog
    :title="$t('components.IntegrationSelector.confirm_title')"
    :text="$t('components.IntegrationSelector.confirm_text')"
    v-model="confirmVisible"
    @confirm="save"
    @cancel="confirmVisible = false"
  />

</template>

<style scoped>

</style>
