import {createVuetify, ThemeDefinition} from "vuetify";
import {aliases, fa} from "vuetify/iconsets/fa";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "@fortawesome/fontawesome-pro/css/all.css";
import { getCookie } from "typescript-cookie";
import "@/styles/main.scss";

export default (light: ThemeDefinition, dark: ThemeDefinition) => createVuetify({
  components: {
    ...components,
  },
  directives,
  icons: {
    defaultSet: "fa",
    aliases,
    sets: {
      fa
    }
  },
  theme: {
    defaultTheme: getCookie("theme") || "light",
    themes: {
      light,
      dark
    }
  }
});
