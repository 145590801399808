<template>
  <v-expansion-panel-title>
    {{ title }} ({{ permission.entity }})
  </v-expansion-panel-title>
  <v-expansion-panel-text>

    <v-card>
      <v-card-title>{{ $t("common.card_title") }}</v-card-title>
      <v-card-text>
        <v-list density="compact">
          <v-list-item v-for="ability in permission.abilities" :key="ability.action+ability.entity">
            {{ ability.action.toUpperCase() }}: {{ ability.entity }}
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-expansion-panel-text>

</template>

<script setup lang="ts">
import {onMounted, PropType, ref} from "vue";
import {Entity, Permission} from "@YenzaCT/sdk";
import yapi from "@/lib/yapi";

const props = defineProps({
  permission: {
    type: Object as PropType<Permission>,
    required: true,
  }
});

const title = ref<string>("");

onMounted(async () => {
  if (props.permission.entity === Entity.Tenant) {
    const tenant = await yapi.admin.tenant.get(props.permission.entityId);
    title.value = tenant.data.title;
  } else if (props.permission.entity === Entity.Institution) {
    const institution = await yapi.admin.institution.get(props.permission.entityId);
    title.value = institution.data.title;
  } else if (props.permission.entity === Entity.Cohort) {
    const cohort = await yapi.admin.cohort.get(props.permission.entityId);
    title.value = cohort.data.title;
  } else {
    throw new Error(`Unknown entity: ${props.permission.entity}`);
  }
});
</script>
