<template>
  <div class="mb-6"><p>{{ $t("components.AssessmentsDetail.instructions") }}</p></div>
  <v-expansion-panels v-model="assessmentsPanel">
    <v-expansion-panel v-if="featureAllowed(Feature.WorkerTypeReport)">
      <v-expansion-panel-title>
        <v-icon color="primary" class="mr-3">far fa-compass</v-icon>
        {{ $t("assessments.holland_code.title") }}
      </v-expansion-panel-title>
      <v-expansion-panel-text v-if="user.assessments.holland_code">
        <holland-code-report
          v-if="user.assessments.holland_code"
          :score="user.assessments.holland_code"
          :user="user"
          data-pw="hollandCodeReportCard"/>
      </v-expansion-panel-text>
      <v-expansion-panel-text v-else>
        {{ $t("assessments.assessment_not_done") }}
      </v-expansion-panel-text>
    </v-expansion-panel>

    <v-expansion-panel v-if="featureAllowed(Feature.PersonalityTraitsReport)">
      <v-expansion-panel-title>
        <v-icon color="primary" class="mr-3">far fa-street-view</v-icon>
        {{ $t("assessments.big_5.title") }}
      </v-expansion-panel-title>
      <v-expansion-panel-text v-if="user.assessments.big_5">
        <big5-report :score="user.assessments.big_5"/>
      </v-expansion-panel-text>
      <v-expansion-panel-text v-else>
        {{ $t("assessments.assessment_not_done") }}
      </v-expansion-panel-text>
    </v-expansion-panel>

    <v-expansion-panel v-if="featureAllowed(Feature.LearningStylesReport)">
      <v-expansion-panel-title>
        <v-icon color="primary" class="mr-3">far fa-school</v-icon>
        {{ $t("assessments.learning_styles.title") }}
      </v-expansion-panel-title>
      <v-expansion-panel-text v-if="user.assessments.learning_styles">
        <learning-styles-report :score="user.assessments.learning_styles"/>
      </v-expansion-panel-text>
      <v-expansion-panel-text v-else>
        {{ $t("assessments.assessment_not_done") }}
      </v-expansion-panel-text>
    </v-expansion-panel>

    <v-expansion-panel v-if="featureAllowed(Feature.EmotionalIntelligenceReport)">
      <v-expansion-panel-title>
        <v-icon color="primary" class="mr-3">far fa-smile</v-icon>
        {{ $t("assessments.emotional_intelligence.title") }}
      </v-expansion-panel-title>
      <v-expansion-panel-text v-if="user.assessments.managing_emotion &&
        user.assessments.understanding_emotion &&
        user.assessments.perceiving_emotion">
        <emotional-intelligence-report
          :managing-emotion-score="user.assessments.managing_emotion"
          :understanding-emotion-score="user.assessments.understanding_emotion"
          :perceiving-emotion-score="user.assessments.perceiving_emotion"
        />
      </v-expansion-panel-text>
      <v-expansion-panel-text v-else>
        {{ $t("assessments.assessment_not_done") }}
      </v-expansion-panel-text>
    </v-expansion-panel>
    <v-expansion-panel v-if="featureAllowed(Feature.CognitiveAbilityReport)">
      <v-expansion-panel-title>
        <v-icon color="primary" class="mr-3">far fa-cogs</v-icon>
        {{ $t("assessments.cognitive_ability.title") }}
      </v-expansion-panel-title>
      <v-expansion-panel-text v-if="user.assessments.maze &&
        user.assessments.rock_paper_scissors">
        <cognitive-ability-report
          :maze="user.assessments.maze"
          :rockPaperScissors="user.assessments.rock_paper_scissors"
        />
      </v-expansion-panel-text>
      <v-expansion-panel-text v-else>
        {{ $t("assessments.assessment_not_done") }}
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script setup lang="ts">
import {PropType, ref} from "vue";
import Big5Report from "@/pages/UserDetailPage/AsessmentsDetail/Big5Report.vue";
import HollandCodeReport from "@/pages/UserDetailPage/AsessmentsDetail/HollandCodeReport.vue";
import {AllowedFeature, DashUser, Feature, AccountType} from "@YenzaCT/sdk";
import LearningStylesReport from "@/pages/UserDetailPage/AsessmentsDetail/LearningStylesReport.vue";
import EmotionalIntelligenceReport from "./AsessmentsDetail/EmotionalIntelligenceReport.vue";
import CognitiveAbilityReport from "@/pages/UserDetailPage/AsessmentsDetail/CognitiveAbilityReport.vue";

const props = defineProps({
  user: {
    type: Object as PropType<DashUser>,
    required: true
  },
  accountType: {
    type: String as PropType<AccountType>,
    required: true
  }
});

function featureAllowed(feature: Feature) {
  return AllowedFeature[props.user?.country][props.user.app.accountType].includes(feature);
}
const assessmentsPanel = ref([0]);
</script>

<style scoped>

</style>
