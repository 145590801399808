<template>
  <v-card color="#F5F5F8">
    <v-card-text>
      <v-row class="pa-4">
        <v-col cols="12" class="center-align">
          <div v-html="sVGIcon" class="sVGIcon"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="center-align">
          <h4>
            {{ title }}
          </h4>
        </v-col>
        <v-col cols="12" class="center-align">
          <v-progress-linear
            :color="colour"
            :model-value="score"
            height="10px"
            rounded
          />
        </v-col>
        <v-col cols="12" class="center-align">
          <h3>
            {{ totalRoundedScore }} / 100
          </h3>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="ts" setup>
import {PropType} from "vue";

const props = defineProps({
  title: {
    type: String as PropType<string>,
    required: true
  },
  colour: {
    type: String as PropType<string>,
    required: true
  },
  sVGIcon: {
    type: String as PropType<string>
  },
  score: {
    type: Number as PropType<number>,
    required: true
  }
});
const totalRoundedScore = Math.round(props.score);
</script>

<style scoped>
.center-align {
  justify-content: center;
  display: flex;
}

.sVGIcon {
  height: 15px;
}
</style>
