<template>
  <v-expansion-panels>
    <template v-if="userProfile">
      <subject-choices-panel
        :curriculum="userProfile.schoolCurriculum || ''"
        :subject-choices="userProfile.schoolCurriculumChoices"
      />

      <favourited-careers-panel :careers="userProfile.favourites.careers" />
    </template>
  </v-expansion-panels>
</template>

<script setup lang="ts">
import SubjectChoicesPanel from "@/pages/UserDetailPage/ChoicesDetail/SubjectChoicesPanel.vue";
import FavouritedCareersPanel from "@/pages/UserDetailPage/ChoicesDetail/FavouritedCareersPanel.vue";
import {PropType, ref} from "vue";
import {DashUser} from "@YenzaCT/sdk";

const props = defineProps({
  user: {
    type: Object as PropType<DashUser>,
    required: true
  },
});

const userProfile = ref(props.user.profile);
</script>
