<template>
  <h3>{{ $t("common.researchAssessments") }}</h3>
  <progress-detail v-if="researchObject.HollandCode" :is-complete="researchObject.HollandCode" data-pw="hollandCodeListItem">
    {{ $t("assessments.holland_code.title") }}
  </progress-detail>
  <progress-detail v-if="researchObject.Big5" :is-complete="researchObject.Big5"
                   data-pw="big5ListItem">
    {{ $t("assessments.big_5.title") }}
  </progress-detail>
  <progress-detail v-if="researchObject.HollandCode"
                   :is-complete="researchObject.HollandCode" data-pw="entrepreneurialIntentListItem">
    {{ $t("assessments.entrepreneurial_intent.title") }}
  </progress-detail>
  <progress-detail v-if="researchObject.LearningStyles" :is-complete="researchObject.LearningStyles"
                   data-pw="learningStylesListItem">
    {{ $t("assessments.learning_styles.title") }}
  </progress-detail>
  <progress-detail v-if="researchObject.ManagingEmotion"
                   :is-complete="researchObject.ManagingEmotion" data-pw="managingEmotionListItem">
    {{ $t("assessments.managing_emotion.title") }}
  </progress-detail>
  <progress-detail v-if="researchObject.PerceivingEmotion"
                   :is-complete="researchObject.PerceivingEmotion" data-pw="perceivingEmotionListItem">
    {{ $t("assessments.perceiving_emotion.title") }}
  </progress-detail>
  <progress-detail v-if="researchObject.UnderstandingEmotion"
                   :is-complete="researchObject.UnderstandingEmotion" data-pw="understandingEmotionListItem">
    {{ $t("assessments.understanding_emotion.title") }}
  </progress-detail>
  <progress-detail v-if="researchObject.RockPaperScissors"
                   :is-complete="researchObject.RockPaperScissors" data-pw="rockPaperScissorsListItem">
    {{ $t("assessments.rock_paper_scissors.title") }}
  </progress-detail>
  <progress-detail v-if="researchObject.Maze" :is-complete="researchObject.Maze"
                   data-pw="mazeListItem">
    {{ $t("assessments.maze.title") }}
  </progress-detail>
</template>
<script setup lang="ts">
import ProgressDetail from "@/pages/UserDetailPage/ProgressCard/ProgressDetail.vue";
import {computed, PropType} from "vue";
import {Assessments} from "@YenzaCT/sdk";

const props = defineProps({
  assessments: {
    type: Object as PropType<Assessments>,
    required: true
  },
  research: {
    type: Array as PropType<string[]>,
    required: true
  }
});

const researchObject = computed(() => {
  const obj: { [key: string]: boolean } = {};

  props.research.forEach((assessment) => {
    obj[assessment] = true;
  });

  return obj;
});
</script>
